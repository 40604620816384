/* eslint-disable */
/* 当前文件对应的国际化词库版本号: 7.9.2.202412231223 */
export default {
About: "約",
AboutUs: "关于我们",
AccordingTo12306TicketsCanOnlyBePurchasedAfterThePhoneNumberHasBeenVerified: "12306规定，手机号核验通过方可购票",
AccordingToYourCompanysTravelPolicy: "根据贵司规定，您未选择最低价舱位航班",
Account: "我的",
AccountLogin: "账号登录",
AccountName: "户名",
AccountNotExist: "用户名不存在",
AccountNumber: "账单号",
AccountNumber_1: "账号",
AccountProtocol: "《账户授权协议》",
AccuratelyFillInTheAgeOfEachChildForSpecialOffers: "准确填写每位儿童的年龄，以便我们为您查找最合适的房型及特价优惠。",
AContactAlreadyExistsWithTheSamePhoneNumber: "已存在相同手机号的联系人",
ActivityNotice: "活动通知",
ActivityNoticeDesc: "优惠活动提醒",
ActualPayment: "实付款",
ActualTotalPrice_x: "实际花费%a$",
Add: "添加",
AddAnotherTrip: "添加其他车次",
AddBusiness: "新增(因公)",
AddContact: "添加联系人",
AddCostCenter: "新增成本中心",
AddDay_x: "+%a$天",
AddDesignatedApprover: "添加指定审批人",
Added: "已添加",
AddFailed: "添加失败",
AddFlight: "再加一程",
AddFrequentContacts: "新增常用联系人",
AddFrequentTraveller: "添加为常旅客",
AddInvoiceHeader: "新增发票抬头",
AdditionalTaxesFees: "另付税/费",
AddManually: "手动添加",
AddPassenger: "添加乘客",
AddPersonal: "新增(因私)",
Address: "详细地址",
AddressDistrict: "所在区域",
AddReturnTrip: "添加返程",
AddSecurityExplain: "添加保障，家人更安心",
AddSecurityExplain_1: "出行有保障，家人更安心",
AddSucceed: "添加成功",
AddTiedList: "添加到已绑列表",
AddTransitCity: "出差途经城市皆需添加至目的地",
AddTravelCard: "新增常旅卡",
AddTraveler: "添加 +",
AdministrativeDistrict: "行政区",
AdmissionPolicy: "入住政策",
Adults: "成人",
Adults_1_x: "%a$成人",
Adults_Children_x_x: "%a$成人 %b$儿童",
Adults_x: "成人%a$",
AdultsDesc: ">12周岁(含)",
AfterClickingGoToPay: "点击去支付后，请打开手机微信的扫一扫，扫描二维码支付",
AfterClickingGoToPay_1: "点击去支付后，请打开手机支付宝的扫一扫，扫描二维码支付",
AfterSendingTextMessage: "发送短信后，将收到12306发给您的六位数字验证码，请输入该验证码",
Age_x: "%a$岁",
Agree: "同意",
AgreeAndContinue: "同意并继续",
Agreement: "协议",
AgreementDesc: "协议描述",
AgreementHotels: "协议酒店",
AgreementHotelStarRating: "协议酒店星级",
AgreementHotelTotal: "协议酒店总金额",
AgreementHotelTotalRoomNight: "协议酒店预订总间夜",
AgreementNight: "协议间夜",
AirChinaPassengerBaggageDomesticTransportationGeneralConditions: "国航旅客行李国内运输总条件2017版(中文版)",
AirfareYouSearchedFor: "你搜索过的机票",
AirlineList: "航司列表",
AirlinePassengerMaintenance: "航司客规维护中",
AirLowestFareAcceptance: "最低价票预订比例",
Airport: "机场",
AirportDropOff: "送机",
AirportDropOff_x: "%a$送机",
AirportPickup: "接机",
AirportPickup_x: "%a$接机",
AirportStation: "机场车站",
AirRouteAnalysis: "航线分析(仅经济舱)",
AirRouteAndCarrierAnalysis: "航线及承运商分析",
AirTicketBookingInstructions: "机票预订须知",
AirTicketConsumptionInDifferentCities: "各城市机票消费情况",
AirTickets: "机票价",
Aisle: "过道",
Alipay: "支付宝",
AliPay: "支付宝支付",
All: "全部",
AllBookings: "我的订单",
AllCabin: "全部舱位",
AllCabins: "全部舱位",
AllCollection: "全部收藏",
AllCompanyPayment: "全部公司支付",
AllLoaded: "全部加载完毕",
AllOrdersForRecentOneMonth: "已显示近6个月出差申请单",
AllOrdersForRecentOneYear: "已显示全部订单",
AllOrdersForRecentOneYear_1: "已显示近1年全部订单",
AllOrdersForRecentSixMonths: "已显示近6个月全部订单",
AllSelected: "全选",
AlreadyHave12306Account: "已有12306账号",
AlreadySetting: "已设置",
Amount: "金额",
Amount_1: "金额(含税)",
AmountOfBillForTheCurrentPeriod: "本期账单金额",
AmountOfConsumption: "消费金额",
AmountPaidInThisPeriod: "本期已还金额",
AmountPayableInTheCurrentPeriod: "本期应付金额",
And: "和",
AndroidPersonalizedRecommendation: "个性化推荐",
AndroidPersonalizedRecommendationDesc: "提供个性化推荐功能，提升预订体验",
AndroidSettingPrivacy: "隐私设置",
Announcement: "公告",
AnnouncementInformation: "公告内容",
Any: "不限",
AnyCity: "不限城市",
Appearance: "外观模式",
Applicant: "申请人",
ApplicantForRefund: "退票申请人",
Application: "申请",
ApplicationContent: "申请内容",
ApplicationContent_x: "申请内容: %a$",
ApplicationDate: "申请日期",
ApplicationForApproval: "申请审批",
ApplicationForBook: "申请单预订",
ApplicationFormInformation: "申请单信息",
ApplicationInformation: "申请单信息",
ApplicationIntlFlight: "国际机票申请",
ApplicationIntlHotel: "海外酒店申请",
ApplicationNumber: "申请单号",
ApplicationToReservedNoData: "暂无待预订的申请单",
Apply: "申 请",
ApplyForRefund: "申请退订",
ApplyRefund: "申请退票",
ApplyTime: "申请时间",
ApplyTravel: "申请出差",
ApplyTravelConfirm: "出差申请确认",
ApplyTravelNeeds: "出差需求",
ApplyTravelSegment: "行程信息",
ApplyTravelTime: "出差日期",
ApproachingTakeoff: "临近起飞",
ApprovalExpired: "审批过期",
ApprovalFail: "审批失败",
ApprovalFlow: "审批流",
ApprovalInformation: "审批信息",
ApprovalInformationDesc: "审批状态提醒",
ApprovalRejection: "审批拒绝",
Approvals: "审批",
Approvals_1: "我的审批",
ApprovalSetting: "审批配置",
ApprovalStatus: "审批状态",
ApprovalSubmitted: "审批已提交",
ApprovalTemplate: "审批模板",
ApprovalTemplate_1: "我的审批模板",
ApprovalTemplateDetails: "模板详情",
ApprovalTimeoutNoticeTime: "审批超时通知时间: %a$分钟",
ApprovalType: "审批方式",
ApprovalType_1: "审批类型",
ApprovalWhitelist: "审批白名单",
Approved: "已审批",
ApprovedCanceled: "申请取消",
ApprovedPass: "审批通过",
Approver: "审批人",
ApproversHaveBeenBound: "已绑定指定审批人",
ApproxPay: "约付",
Area: "面积",
AreYouSureToAddThisAsAFrequentTraveler: "确定将其添加为常旅客?",
AreYouSureToCancelTheOrder: "是否确认取消该订单?",
AreYouSureToCancelUnableToSave: "取消后，该页面信息将无法保存，是否仍要取消？",
AreYouSureToChange: "是否确认改签?",
AreYouSureToChangeTheCurrency: "确定更改货币？",
AreYouSureToChangeTheLanguage: "确定更改语言？",
AreYouSureToChangeYourFlightToTheCheapestFlight: "请确认是否将您的航班变更为最低价航班?",
AreYouSureToDelete_x: "确定删除%a$吗？",
AreYouSureToDeleteTheApprover: "确定删除该指定审批人？",
AreYouSureToDeleteTheCertificate: "是否删除该证明？",
AreYouSureToDeleteTheEmployeeFromTheWhitelist: "确定从白名单中删除该员工？",
AreYouSureToDeleteTheRC: "确定删除该RC",
AreYouSureToDeleteTheSelectedCollection: "您确认删除所选收藏吗？",
AreYouSureToDeleteThisCustom: "确定删除该自定义项？",
AreYouSureToDeleteThisEmployeeFromTheTemplate: "确定从模板下删除该员工？",
AreYouSureToPayForThisOrder: "您确定要支付该订单吗?",
AreYouSureToRemove_x: "是否删除该%a$",
AreYouSureToRemoveItFromTheCostCenterList: "确定删除该成本中心？",
AreYouSureToRemoveItFromTheDepartmentsList: "确定删除该部门？",
AreYouSureToRemoveItFromTheEmployeeList: "确定将其从员工列表中删除？",
AreYouSureToRemoveItFromTheFrequentTravellerList: "确认将其从常旅客列表中移除?",
AreYouSureToRemoveItFromTheNonEmployeeList: "确定将其从外部人员列表中删除？",
AreYouSureToRescheduled: "是否确认改期?",
AreYouSureToSeat: "是否确认选座?",
AreYouSureToSubmitAndPayForTheOrder: "是否确认提交并支付订单?",
AreYouSureToSubmitYourOrder: "是否确认提交订单?",
AreYouSureToTurnOffFaceIDLogin: "确定关闭面容登录？",
AreYouSureToTurnOffTouchIDLogin: "确定关闭指纹登录？",
AreYouSureYouWantToDeleteThisAccount: "确认要删除该账号吗？",
AreYouSureYouWantToGiveUpTheInformationChange_x: "%a$有信息改动，确认放弃更改？",
AreYouSureYouWantToLogout: "确认要退出该账号吗？",
AreYouSurYouWantToDeleteThisAccount: "确定要删除该账号吗？",
AreYouSurYouWantToLogout: "确定要退出该账号吗？",
Arrival: "到达",
ArrivalCity: "到达城市",
ArrivalCityAddress: "目的地",
ArrivalCityAddressDesc: "出差途经城市皆需添加至目的地",
ArrivalCityOptions: "可选到达城市",
ArrivalDate: "到达日期",
ArrivalEarliest: "到达从早到晚",
ArrivalLatest: "到达从晚到早",
ArrivalStation: "到达车站",
ArrivalTerminal: "到达航站楼",
ArrivalTime: "到达时段",
ArrivalTime_1: "到达时间",
ArrivalTime_2: "到达站/时间",
ArrivalTimeDepartureDate: "到达时间/离店日期",
ArrivalTimeIsLocalTime: "抵达时间为当地时间",
Arrive: "送达",
ArriveAddress: "下车地点",
Associated12306Account: "关联12306账号",
AtNoonToday: "今天中午",
AToB_x_x: "%a$ 至 %b$",
AttachFileUpload_x: "可再传%a$个",
AttachFileUploadExplain_1: "·最多可以上传10个文件，每个文件大小不超过8M",
AttachFileUploadExplain_2: "·支持的文件类型有img、word、ppt、excel、pdf",
AUD: "澳元",
Authentication: "身份验证",
Available: "可用",
Available_x: "%a$可用",
AvailableIntegral: "可用积分",
AvailableTickets: "有票",
AverageBusinessTravelFare: "商旅平均票价",
AverageDiscount: "平均折扣",
AverageDomesticDiscount: "国内平均折扣(经济舱)",
AverageHotelPrice: "酒店均价",
AverageMileagePrice: "里程均价(仅经济舱)",
AverageNumberOfDaysBookedInAdvance: "平均提前预订天数",
AveragePrice: "平均价格",
AveragePriceOfBusinessTravelAgreement: "商旅协议均价",
AveragePriceOfBusinessTravelMileage: "商旅里程均价",
AverageTicketPrice: "平均票价",
AverageTrainTicketPrice: "火车票均价",
AVerificationMessagePleaseEnterTheVerificationCodeYouReceived: "发送了一封验证短信，请输入您收到的验证码",
Avg: "均",
AvgTicketPriceOfMileage: "里程均价",
AwayFrom: "距离",
B: "公",
Baby: "婴儿",
Baby_1_x: "%a$婴儿",
Baby_x: "婴儿%a$",
BabyDesc: "14天(含)-2周岁(不含)",
BabyNonBookable: "婴儿不可订",
BabyNonSupportBookable: "如需预订婴儿票，请联系您的差旅顾问",
BabyReQuery: "该航班婴儿不可订，如需携带婴儿出行，请重新查询可订航班",
BabyTooSmallNonBookable: "出生不满14天的婴儿不可预订",
BabyWith: "带婴儿",
BabyYearsOld: "14天-2岁",
Back: "返回",
BackFlight: "返程航班",
BackModify: "返回修改",
BackTag: "返",
BackToHome: "返回首页",
BackToHotelList: "返回酒店列表",
BackToLogin: "返回登录页面",
BackWeek_x: "%a$返回",
Baggage: "行李",
BaggageAllowance_x: "行李政策：%a$",
BaggagePolicy: "行李规定",
Balance: "余额",
Bank: "开户行",
BankAccount: "账户",
BankCardInformation: "信用卡信息",
BasicInformation: "基本信息",
BasicInformationIsIncompletePleaseEdit: "基本信息不全，请编辑",
BasicInformationIsIncompletePleaseEdit_x: "%a$基本信息不全，请编辑",
Batch: "批量管理",
BatchPass: "批量通过",
BecomeDue_x: "%a$ 到期",
BedType: "床型",
BedWide_x_x: "宽%a$ x %b$",
BeingApprovalByTheFlightCompany: "待航司审核",
BelongCity: "所属城市",
BeOverdueByCarPleaseCheckAgain: "车辆信息可能有变，请重新查询",
BeOverdueByFlightPleaseCheckAgain: "航班可能有变，请重新查询",
BeOverdueByHotelPleaseCheckAgain: "酒店信息可能有变，请重新查询",
BeOverdueByTrainPleaseCheckAgain: "车次可能有变，请重新查询",
BigBed: "大床",
BillEndDate: "账单终止日",
BillGrouping: "账单分组",
BillingPeriod: "账单账期",
BillStartDate: "账单起始日",
Bind: "立即绑定",
BindFailed: "绑定失败",
Binding: "绑定",
BindingCTrip: "携程会员绑定",
BindingCTripDesc: "我们将使用您输入的手机号码绑定携程旅行个人会员，如果您还不是携程会员，恒顺商旅将使用该手机号码为您注册携程会员。",
BindingCTripPrivacy: "阅读并同意携程的《服务协议》和《隐私政策》",
BindingCustomItems: "绑定自定义项",
BindingEmployees: "绑定员工",
BindingHuazhu: "华住会员绑定",
BindingHuazhuDesc: "我们将使用您的信息与华住协议单位进行绑定，如果您还不是华住会员，恒顺商旅将使用该信息为您注册华住会员。",
BindingHuazhuPrivacy: "阅读并同意华住的《服务协议》和《隐私政策》",
BindingLogin: "一键授权绑定",
BindingMeituan: "美团会员绑定",
BindingMeituanDesc: "我们将使用您的信息与美团协议单位进行绑定，如果您还不是美团会员，恒顺商旅将使用该信息为您注册美团会员。",
BindingMeituanPrivacy: "阅读并同意美团的《服务协议》和《隐私政策》",
BindingStatus: "绑定状态",
BindMailbox: "绑定邮箱",
BindMembershioFor_X: "绑定%a$会员卡",
BindMembersToEnjoyLowerPrices: "绑定会员立享更低价格",
BindPerson_x: "已绑定%a$人",
BindSuccessfully: "绑定成功",
BlankImport: "空白导单",
Book: "订",
Book_1: "订票",
Book_2: "立即预订",
Book_3: "预 订",
BookAllStaff: "为全部员工预订",
BookCar: "预订车辆",
BookDesignated_x: "为指定%a$预订",
BookDesignatedDepart: "为指定部门预订",
BookDesignatedStaff: "为指定员工预订",
BookDomesticFlights: "预订国内机票",
BookDomesticHotel: "预订国内酒店",
Booker: "预订人",
BookFinished: "订完",
BookFlight: "预订机票",
BookHotel: "预订酒店",
BookInAdvance: "提前预订",
Booking: "预订",
BookingDaysInAdvance: "提前预订天数",
BookingInformation: "预订信息",
BookingInstructionsAndInstructionsForReturning: "预订须知和退改说明",
BookingLevel: "预订级别",
BookingMail: "预订邮件",
BookingMethods: "预订方式",
BookingRange: "预订范围",
BookingSMS: "预订短信",
BookingSource: "预订来源",
BookingSucceed: "预订成功",
BookingTheBus: "预订汽车票",
BookingTheCar: "预订用车",
BookingTheMeals: "预订用餐",
BookInternationalFlights: "预订国际机票",
BookInternationalHotel: "预订海外酒店",
BookOnlySelf: "仅限帮本人预订",
BookPermissions: "预订权限",
BookTheLowestLogicalFare: "预订最低价",
BookTrain: "预订火车票",
BookType: "预订类型",
Bound: "已绑定",
Bound_1: "已绑员工",
BoundCustomItems: "已绑自定义项",
BoundEmployees: "已绑员工",
Brand: "品牌",
Breakfast: "含早",
Breakfast_1: "早餐",
BrowserOpens: "浏览器打开",
Budget: "预算",
Budget_1_x: "%a$预算",
Budget_x: "预算%a$",
BudgetAmount: "预算金额",
BulkRejection: "批量拒绝",
Business: "因公",
Business_x: "%a$(因公)",
BusinessCenter: "商务中心",
BusinessClass: "公务舱",
BusinessConsultant: "业务顾问",
BusinessDistrict: "商业区",
BusinessDistrictInfrastructureBrandEtc: "商圈/基础设施/品牌 等",
BusinessTravel: "商旅",
BusinessTravelScale: "企业差旅规模(月度)",
BusinessTrip: "因公出行",
BusinessType: "业务类型",
BusinessType_1: "业务",
BusinessUnit: "BusinessUnit",
BusOrderConfirmation: "汽车票订单确认",
BusOrderDetails: "汽车票详情",
BusRank: "汽车票差标",
BusRefundOrderConfirm: "汽车票退票订单确认",
BusStation: "汽车站",
BusTicket: "汽车票",
BusTicketOrder: "汽车票订单",
By: "经",
By_x: "经 %a$",
ByMail: "通过邮箱",
ByPhoneNumber: "通过手机号",
Cabin: "舱等",
CabinInformationHasBeenUpdated: "舱位信息已更新，订单部分内容可能发生变更，请重新填写订单以确保准确性",
CabinLevel: "舱位等级",
CabinType: "舱位类型",
CAD: "加拿大元",
Calendar: "日历",
CallCar: "叫车",
CallingForYou: "正在为你呼叫...",
CallPhone: "打电话",
CallThePolice: "110报警",
Camera: "拍摄",
CanAccommodateUpToXPeople_x: "每间最多住%a$人",
CanAccommodateXPeople_x: "每间可住%a$人",
Cancel: "取消",
Cancel_1: "暂不升级",
Cancel_2: "取消管理",
CancelAnyway: "仍要取消",
Canceled: "已取消",
CancelFailed: "取消失败",
CancelForFree: "免费取消",
CancelInformation: "取消信息",
CancelInquiry: "取消需求单",
Cancelling: "正在取消",
CancelOrder: "取消订单",
CancelReason: "取消原因",
CancelRefund: "取消退票",
CancelRules: "取消规则",
CancelSucceed: "取消成功",
CannotBeCancelled: "不可取消",
CanNotSupportedFileType: "undefined",
CanOnlySelectUpToXGuest_x: "每间房最多只能选择%a$个入住人",
CanOnlySelectUpToXpassengers_x_x: "最多只能选择%a$个%b$",
CanPeopleStaying_x: "可住%a$人",
Car: "用车",
CarAirport: "机场接送",
CarApplication: "用车申请",
CarbonEmission: "碳排放量",
CarbonOffset: "碳补偿(种植棵树)",
CardholderInformation: "持卡人信息",
CarDistance_x: "行驶了%a$公里",
CardNumber: "卡号",
CardType: "卡类型",
CarDuration_x: "共花费%a$分钟",
CarFare: "用车票价",
CarOrder: "用车订单",
CarOrderConfirmation: "用车订单确认",
CarPlatform: "用车平台",
CarRank: "用车差标",
Carrier: "承运人",
Carrier_1: "承运商",
CarrierAnalysis: "承运商分析",
CarriersItineraries: "承运商&航线",
CarScene: "用车场景",
CarServiceTime: "用车时间",
CarTrain: "火车站接送",
CarType: "用车类型",
CellphoneNumber: "手机号",
CertificateInformation: "证件信息",
Change: "改签",
Change_1: "改",
Change_2: "修改航班",
Change_3: "切换12306账号",
ChangeDetails: "改期明细",
ChangeFailed_1: "修改失败",
ChangeFee: "改签费",
ChangeFlight: "修改航班",
ChangeFlightSchedule: "改期航班",
ChangeInstructions: "改签规则",
ChangeMobileNumber: "更换手机号",
ChangeMobileNumber_1: "更 换 手 机 号",
ChangePassword: "修改密码",
ChangePolicy: "更改规定",
ChangeRate: "改签率",
ChangeSchedule: "改签行程",
ChangeSegment: "更改行程",
ChangeStation: "变更到站",
ChangeSucceed: "改签成功",
ChangeSuccessFailed: "改签失败",
ChangeSuccessfully: "改签成功",
ChangeTheNumberOfPeople_x: "您选择的乘机人有变化，为您调整为%a$，如不满足您的需要，您可以自行修改乘机人",
ChangeTheNumberOfRoomsAndPeople_x_x: "您选择的入住人数有变化，为您调整为%a$间%b$人，如不满足您的需要，您可以自行修改房间入住人数",
ChangeTo_x: "更改为%a$",
ChangeYourFlightAndContinueDeparting: "换个航班，继续出发~",
Changing: "改签中",
Charge: "收费",
ChargebackDateTime: "退单申请时间",
ChargebackDetails: "退单详情",
ChargebackNumber: "退单号",
ChargebackNumber_x: "退单号%a$",
ChargebackRoom: "取消间数",
ChargebackTime: "取消日期",
ChargeByAirlineRefundRules: "按航空公司退改规则收费",
ChargedAccordingToTheCorrespondingAirlineTicketRegulations: "根据相应航空公司客票规定(NFD)收取",
CheapestFlight: "最低价航班",
CheapFlights: "特价机票",
Check: "核验",
CheckerInformation: "入住人信息",
CheckIn: "入住",
CheckIn_x: "%a$入住",
CheckInCheckOut_x_x_x: "%a$ 入住，%b$ 离店，%c$晚",
CheckInCity: "入住城市",
CheckInCounterIsClosedNotBookedYet: "值机柜台已关闭，暂不预订",
CheckInDate: "入住日期",
CheckInformation: "核对信息",
CheckingForTheLatestPriceForYou: "正在为你查询最新价格，请稍后…",
CheckInHotel: "入住酒店",
CheckInRules: "入住规则",
CheckInSeat: "值机选座",
CheckInToCheckoutNight_x_x_x: "%a$ 至 %b$ %c$晚",
CheckInToCheckoutNight_x_x_x_x_x: "%a$ %b$ 入住，%c$ %d$ 离店，%e$晚",
CheckInToCheckoutNightAndRoom_x_x_x_x: "%a$ 至 %b$ %c$晚/%d$间",
CheckInToCheckoutNightAndRoomAndRoom_x_x_x_x_x: "%a$ 至 %b$ · %c$晚%d$间 · %e$",
CheckOrder: "查看订单",
CheckOut: "离店",
CheckOut_x: "%a$离店",
CheckOutDate: "离店日期",
CheckTheDifference: "点击查看差标详情",
CHF: "瑞士法郎",
ChildOfAge: "儿童年龄",
Children: "儿童",
Children_1_x: "%a$儿童",
Children_x: "儿童%a$",
ChildrenAndBabyExplain: "儿童婴儿预订说明",
ChildrenDesc: "2周岁(含)-12周岁(不含)",
ChildrenNonBookable: "儿童不可订",
ChildrenNonSupportBookable: "如需预订儿童票，请联系您的差旅顾问",
ChildrenReQuery: "该航班儿童不可订，如需携带儿童出行，请重新查询可订航班",
ChildrenWith: "带儿童",
ChildrenYearsOld: "2-12岁",
China: "国内",
China_1: "国内(含港澳台) 目的地",
China_3: "中国",
ChinaRegion: "国内(含港澳台)",
ChinaSouthernAirlinesNewRegulations: "南航新规提醒：乘客可通过南航APP和微信退改本人机票，并可直接获取退票款。如乘客私自到南航退款，我司将仍按原订机票费用向贵司全额收取。提醒贵司做好监管，否则存在风险。",
ChineseName: "中文姓名",
Choose: "选择",
Choose_x: "选择%a$",
ChooseAirport: "选择机场",
ChooseAtLeastXPeople_x: "至少需要选择%a$位入住人",
ChooseAtMostContact_x: "最多只能选择%a$名联系人",
ChooseBU: "选择BU",
ChoosePhotos: "选择照片",
ChooseTheLowestPriceToSave: "选择最低价节省",
ChooseTheReason: "选择原因",
ChooseTrainStation: "选择火车站",
ChooseUpToXPeople_x: "最多只能选择%a$位入住人",
CI: "入住",
CICO: "入离日期",
CICO_1: "入离店日期",
City: "城市",
City_1: "按起降城市",
CityAnalysis: "城市分析",
ClassAnalysis: "舱位分析",
ClassHotel: "型酒店",
ClassRoomTypeSeat: "舱等/房型/坐席",
Clear: "清空",
ClearCache: "清除缓存",
ClearHistory: "清空历史记录",
ClickLogin: "点击登录",
ClickNextToIndicateThatYouHaveAcceptedAndAreAwareOfTheRefundPolicyForThisTicket: "点击下一步表示已接受并知晓此机票的退改签规则",
ClickRefresh: "点击刷新",
ClickShrink: "点击收起",
ClickToResend: "点击重新发送",
ClickToSetTheAddress: "点击设置地址",
ClickToView: "点击查看",
Close: "关闭",
Closed: "已关闭",
CloudBill: "云账单",
CloudQuickPay: "云闪付",
CN: "中",
CNYStart_x: "%a$起",
CO: "离店",
CO2Emissions: "碳排放",
CodeShare: "共享",
ComfortHotel: "舒适型酒店",
ComfortHotel_1: "舒适",
CommentsDetails: "评论详情",
CommodityDetails: "商品详情",
CommonOperations: "常用操作",
Company: "公司",
CompanyAndPersonalPayment_1_x_x: "公司支付%a$，个人支付%b$",
CompanyAndPersonalPayment_x_x: "公司%a$ 个人%b$",
CompanyInfo: "单位信息",
CompanyInvoiceHeader: "公司抬头",
CompanyName: "企业名称",
CompanyPayment: "公司支付",
CompanyPaymentPrepay: "公司支付(预付款)",
CompanyTel: "公司联系电话",
ComplaintsSuggestions: "意见反馈",
Completed: "已完成支付",
CompleteVerification: "完成核验",
Confirm: "确定",
Confirm_1: "确认",
Confirm_2: "选好了",
Confirm_3: "确 定",
ConfirmationOrder: "确认单",
ConfirmDeparture: "确定离开",
Confirmed: "已确认",
ConfirmedCallCar: "已确认，立即叫车",
ConfirmedCheckInTimeAtTheCounterCanBeBooked: "已到柜台确认值机时间，继续预订",
ConfirmImmediately: "立即确认",
Confirming: "确认中",
ConfirmMail: "确认邮件",
ConfirmPay: "确认支付",
ConfirmRules: "确认规则",
ConfirmSelectedTraveler_x: "确认选择·%a$人",
ConfirmSelection: "确认选择",
ConfirmSMS: "确认短信",
ConfirmTheChanges: "确认修改",
ConfirmTheChanges_1: "确 认 修 改",
ConfirmTheNewPassword: "确认新密码",
ConfirmTheRemovalOfTheirDocuments: "确认将其证件移除?",
ConfirmToResendApprovalInformation: "是否确认重发审批信息?",
ConfirmUnbinding: "确认解绑？",
ConfirmUpdate: "确认更新",
Consignee: "收货人",
Consultant: "专属顾问",
ConsumptionOfCarsInDifferentCities: "各城市用车消费情况",
ConsumptionOfTrainTicketsInEachCity: "各城市火车票消费情况",
ContactCustomerService: "联系客服",
ContactDetails: "联系人详情",
ContactDriver: "联系司机",
ContactEmailFormatIsIncorrect_x: "联系人%a$的邮箱格式不正确",
ContactHotel: "联系酒店",
ContactInfo: "联系方式",
ContactInformation: "联系人信息",
ContactInformationOfExclusiveConsultant_x: "专属顾问%a$的联系方式",
ContactMobileFormatIsIncorrect_x: "联系人%a$的手机号格式不正确",
ContactName: "联系人姓名",
ContactNumber: "联系电话",
ContactNumberColon: "联系电话: ",
ContactPhoneNumber: "联系人手机号码",
ContactPhoneNumberCannotBeDuplicated: "联系人手机号不能重复",
Contacts: "联系人",
Contacts_x: "联系人%a$",
ContactTheEmailNotificationHasBeenTurnedOn_x: "联系人%a$已开启邮件通知功能, 邮箱地址不能为空!",
Content: "内容",
ContentIsEmpty: "内容为空",
Continue: "继续",
ContinueBooking: "继续预订",
ContinueBooking_1: "继 续 预 订",
ContinueBookingWithoutRescheduling: "不改期，继续预订",
ContinuetoBook: "继续预订原航班",
Copy: "份",
Copy_1: "复制",
CopyInvitationLink: "复制邀请链接",
CopyLink: "复制pdf链接",
CopySuccess: "已复制到剪切板",
CopyTheLink: "复制链接",
CopyToClipboard: "复制到剪切板",
CorporateTourism: "企业旅游",
CostCenter: "成本中心",
CostCenterName: "成本中心名称",
CreationDate: "创建日期",
CreationTime: "创建时间",
Credential: "证件",
CreditCard: "信用卡",
CreditCardGuarantee: "信用卡担保",
CreditGuarantee: "信用担保",
Currency: "货币",
CurrencyExplain_x: "货币：%a$ 每位成人票价",
CurrencySelectedIncorrectly: "选择币种有误，请重新选择",
CurrencySetting: "货币设置",
CurrentAdvancePayment: "当前预付款",
CurrentCityNoCarService: "当前城市该时段暂未提供服务",
CurrentIntergral: "当前积分",
CurrentLxd: "当前可用旅行豆",
CurrentlyBusiness: "当前只开通了因公查询",
CurrentlyOnlineInsuranceOnlySupportsTheFirstSegment: "目前线上保险仅支持购买第一程",
CurrentlyOnlyDomesticAirTickets: "目前只支持国内机票。违反机票差旅标准，需要选择的原因",
CurrentlyPrivate: "当前只开通了因私查询",
CurrentMobile: "当前手机号: ",
CurrentOrderGeneratedOrder_x: "当前下单生成了%a$个订单",
CurrentOrderGeneratedOrderYouNeedToPayInTurn_x: "当前下单生成了%a$个订单，您需要依次支付",
CurrentPageDownload: "当前页下载",
CurrentPassword: "当前密码",
CurrentPosition: "当前位置(仅供参考)",
CurrentVersion: "当前版本",
CustomerChoosesThePrice: "客户选择价格",
CustomerExperienceFirst: "客户体验第一",
CustomerServiceSupport: "客户服务支持",
CustomItems: "自定义项",
CustomizationsBound: "已绑定自定义项",
CustomUseLxd: "自定义使用旅行豆数量",
Dark: "深色模式",
DataUpTo: "数据更新至",
Date: "日期",
DateIsTheLocalDateOfDepartureCity: "您选择的是出发城市的当前日期",
DateOfBirth: "出生日期",
DateOptions: "可选日期",
Days_1_x: "+%a$天",
Days_x: "%a$天",
DeductionLxd: "抵扣",
DeductionRequired: "需扣款项",
Default: "默认",
Delete: "删除",
Delete_1: "移除",
DeleteSuccessFailed: "删除失败",
DeleteSuccessful: "删除成功",
DeliveryAddress: "配送地址",
DeliveryAfterSuccessfulBooking: "预订成功后送",
DeliveryInfo: "配送信息",
DeliveryMethod: "配送方式",
DepartingColon: "去:",
Department: "部门",
DepartmentName: "部门名称",
Departure: "出发",
Departure_1: "上车地点",
DepartureCity: "出发城市",
DepartureCityAddress: "出发地",
DepartureCityAndArrivalCityCannotBeSame: "出发城市和到达城市不能相同",
DepartureCityAndArrivalCityCannotBeSame_x: "第%a$程出发城市和到达城市不能相同",
DepartureCityOptions: "可选出发城市",
DepartureDate: "出发日期",
DepartureDateLocation: "出发日期(当地时间)",
DepartureDateOptions: "可选出发日期",
DepartureEarliest: "从早到晚",
DepartureEarliest_1: "出发从早到晚",
DepartureLatest: "从晚到早",
DepartureLatest_1: "出发从晚到早",
DepartureStation: "出发车站",
DepartureTerminal: "出发航站楼",
DepartureTime: "出发时段",
DepartureTime_1: "出发站/时间",
DepartureTime_2: "起飞时间",
DepartureTime_3: "起飞时间段",
DepartureTimeCheckInDate: "出发时间/入住日期",
DepartureWeek_x: "%a$出发",
DesignateApprover: "指定审批人",
Destination: "目的地",
DestinationOptions: "可选目的地",
Detail: "明细",
Details: "详情",
Details_1: "查看详情",
DetailsOfThisMonth: "本月明细",
DidiDriverIsComing: "司机正在赶来",
DifferentFromTheDepartingAirport: "与去程出发机场不同",
DifferentFromTheOriginalScheduledArriveAirport: "与原订行程到达机场不同",
DifferentFromTheOriginalScheduledDepartureAirport: "与原订行程出发机场不同",
DifferentFromTheOriginalScheduledDepartureAndrArriveAirport: "与原订行程出发机场和到达机场不同",
DiffPrice: "差价",
Dimension: "分析维度",
Dimension_1: "多维度分析",
Diner: "用餐人",
DinerDetail: "用餐人详情",
DinerInfomation: "用餐人信息",
DinerName: "用餐人姓名",
Dingtalk: "钉钉",
DingTalkByAccount: "钉钉账号",
DingTalkByUser: "钉钉员工绑定",
Direct: "直达",
DirectFlightFirst: "直飞优先",
DirectionsForUse: "使用说明",
Disagree: "不同意",
DiscountDistribution: "折扣分布",
DiscountInterval: "折扣区间",
DistanceCenter_x_x: "距离市中心%a$·%b$",
DistanceFromYou_x: "距离您%a$",
DistanceLowToHigh: "直线距离近-远",
DistanceNearToFar: "按距离从近到远",
DistanceToDestination_x: "距离目的地%a$",
DocumentInformationVerificationFailed: "证件信息核验未通过",
DocumentSignatory: "证件签署国",
DomAverageDiscount: "国内平均折扣(仅经济舱)",
DomesticFlights: "国内机票",
DomesticFlights_1: "国内机票(不含港澳台地区)",
DomesticFlightsApplication: "国内机票申请",
DomesticFlightsOrderConfirmation: "国内机票订单确认",
DomesticHotel: "国内酒店",
DomesticHotel_1: "国内酒店(含港澳台地区)",
DomesticHotelApplication: "国内酒店申请",
DomesticHotelOrderConfirmation: "国内酒店订单确认",
DomesticHotelOrders: "国内酒店订单",
DomesticServicePhone: "境内服务热线",
DomesticTicketChange: "国内机票改期",
DomesticTicketChangeApplication: "国内机票改期申请",
DomesticTicketOrder: "国内机票订单",
DomesticTicketRefundApplication: "国内机票退票申请",
DomFullFareTicketRate: "国内全价票比例",
DoNotChange: "不可更改",
DoNotChangeBack: "不可退改",
DoNotUse: "暂不使用",
DoNotUseLxd: "暂不使用旅行豆",
DoubleSIMDeviceTip: "当前设备为双卡设备，请注意选择号码发送短信",
Download: "下载",
DownloadAll: "全部下载",
Downloaded: "已下载",
DownloadFailed: "下载失败",
Downloading_x: "下载中...%a$%",
DownloadPolicy: "下载保单",
DownloadSchedule: "下载明细表",
DownloadTheQRcode: "下载二维码",
DowntownCenter: "市中心",
DoYouWantToLeaveHumanCustomerService: "是否离开人工客服",
DoYouWantToSwitchToPerson: "是否切换至会员账号？",
DoYouWantToSwitchToUnit: "是否切换至单位账号？",
DrillMultipleChoice: "钻级(可多选)",
DriverArrival: "司机已到达",
DropDownToViewHistory: "下拉查看历史消息",
Duration: "行程用时",
DutyParagraph: "公司税号",
DwellTime: "停留时间",
Dysfunction: "功能异常",
E: "终",
EachRoomNeedsToFillIn_x: "每间需填写%a$人",
EarlyThisMorning: "今天凌晨",
EarlytoLate: "起飞时间 早-晚",
Economic: "经济",
EconomicHotel: "经济型酒店",
EconomicHotel_1: "经济",
Economy: "经济",
EconomyClass: "经济舱",
EconomyClassDiscountBelow_x: "经济舱 (%a$折及以下)",
Edit: "编辑",
Edit_x: "编辑%a$",
EditContact: "修改联系人",
EditContactFailed: "修改联系人失败",
EditCostCenter: "编辑成本中心",
EditDepartment: "编辑部门",
EditFrequentContact: "编辑常用联系人",
EditInvoiceHeader: "编辑发票抬头",
EditRC: "编辑RC",
Effective: "已生效",
EffectiveDate: "证件有效期",
ElectronicInvoice: "电子发票",
Email: "邮箱",
EmailAddress: "邮箱地址",
EmailApproval: "邮件审批",
EmailFormatIsIncorrect: "邮件格式不正确！",
EmailFormatIsIncorrect_x: "%a$的邮箱格式不正确",
EmergencyContactMobile: "紧急联系人号码",
EmergencyContactName: "紧急联系人姓名",
EmergencyContactNumber: "紧急联系电话",
EmergencyHelp: "紧急求助",
Emission: "排放量(kg)",
EmployeeDetails: "员工详情",
EmployeeInformation: "人员信息",
EmployeeManagement: "员工管理",
EmployeeNumber: "员工数",
EmployeesCanApply: "员工访问链接可申请加入团队",
EmployeesCcanScan: "员工可扫描二维码加入团队",
EmptyFileCannotBeUploaded: "空文件无法上传，请重新选择",
EN: "英",
EnableFingerprintLogin: "是否开通指纹登录",
End: "结束",
EndDate: "结束日期",
EnglishName: "英文姓名",
EnquiryOrderNumber: "需求单号",
EnterANewPassword: "输入新密码",
EnterflightNoMU5166: "请输入航班号，如MU5166",
EnteringKefu: "正在连接人工客服",
EnterMembershipNumber: "请输入会员卡号",
EnterNewMobile: "请输入新的手机号",
EnterPhoneNumber: "请输入手机号",
EnterpriseCertification: "企业认证",
EnterpriseCooperation: "企业合作",
EnterTheLastFourDigitsOfYourID: "证件号后四位",
EnterVerificationCode: "请输入验证码",
EnterVerificationCode_1: "输入验证码",
EnterVerificationCodeForThe12306: "请输入12306回复的验证码",
EnterYourNewPasswordAgain: "再次输入新密码",
Estimate: "预估",
EstimateMeteredBilling: "预估(打表计费，以实际为准)",
ETA: "预计到店",
Etc: "...等航司",
EUR: "欧元",
EvaluateKefu: "请对本次服务做出评价",
ExceedingOrderAmount: "超出满减限制",
Exchange: "兑换",
Exchange_1: "立即兑换",
ExchangeSerialNumber: "兑换流水号",
ExchangeSuccessful: "兑换成功",
ExcludingFullPriceAverageDiscount: "不含全价平均折扣",
ExclusiveBrandPrivileges: "尊享品牌特供权益",
ExclusiveConsultant_x: "专属顾问%a$",
ExistingBusinessTravelAccount: "已是恒顺商旅签约企业",
ExistingStaff: "已有员工",
ExistingStaff_x: "已有员工(%a$)",
Exit: "安全出口",
ExitWarning: "退出将解除微信与此账号的绑定，解除绑定将不能接收此账号微信消息通知，确定要退出吗?",
ExpandMore: "展开更多",
ExpectToAt: "预计",
Expired: "已过期",
ExportOrder: "下载订单明细",
Express: "快递",
ExpressDelivery: "快递配送",
Ext: "分机号",
ExtendedInformation: "扩展信息",
Face: "人脸核验",
FaceIDIsNotEnabled: "当前设备没有开通面容ID",
FaceIDIsOnlyValidForTheMachine: "面容ID仅针对本机有效",
FaceIDLogin: "面容登录",
FacilitiesDetails: "设施/详情",
FacilityServices: "设施服务",
FailedToAccessTheCamera: "访问摄像头失败",
FailedToChangePassword: "修改密码失败",
FailedToGetPleaseTryAgainLater: "获取失败，请稍候再试",
FailedToResetPassword: "重置密码失败",
FalseAlarmAccountability: "谎报警情，依法追责",
FalseAlarmAccountabilityDesc_1: "一键报警接通平台客服，如遇危害人身及财产安全的紧急情况，请直接拨打110。",
FalseAlarmAccountabilityDesc_2: "谎报警情将可能被处以五日以上十日以下拘留",
FeaturedIntroduction: "特色介绍",
Feedback: "意见反馈",
Feishu: "飞书",
FeishuByAccount: "飞书账号",
FeishuByUser: "飞书员工绑定",
Female: "女",
File: "文件",
FileDownloadFailed: "文件获取失败",
FileSizeLimit_x: "文件仅支持%a$Mb以下大小",
FillInAccordingToTheActualNumber: "按照实际人数填写",
FillInAccurateAndCompleteUnitInformation: "请填写准确完整的单位信息，以便更好为您服务",
FillingGuide: "填写指南",
FillInTheReason: "填写原因",
FillInYourBoundEmailAddress: "填写您绑定的邮箱地址",
Filter: "筛选条件",
Filter_1: "高级筛选",
Filters: "筛选",
FindANewQueryQuote: "找到新的查询报价",
FingerprintDoItAgain: "验证失败，再试一次",
FingerprintTouch: "轻触摸指纹传感器",
FingerprintVerification: "验证指纹",
FingerprintVerificationTooManyTimes: "指纹验证次数过多，请稍后再试",
Finish: "完成",
Finished: "已完成",
First: "第",
FirstBusinessClass: "头等/公务舱",
FirstClass: "头等舱",
FirstClassSeat: "一等座",
FirstNameFormatIsIncorrect: "英文名格式不正确！",
FirstStep: "第一步",
FixedPrice: "一口价",
Flight_x: "%a$航班",
FlightBookPermission: "机票预订权限",
FlightCancellationDelayForMoreThan: "航班取消/延误30分钟以上，以航空公司审核为准",
FlightConfirmationWarn: "用于办理签证和出入境审查，可打印确认单并在出行时随身携带",
FlightDate: "航班日期",
FlightDeparturesAreLocalTime: "航班起降均为当地时间",
FlightDetails: "航班详情",
FlightDynamicsAreForReferenceOnly: "航班动态仅供参考，请以航司发布为准",
FlightIndex_x: "第%a$程",
FlightInformation: "航班动态",
FlightInformationDesc: "航班变更等行程提醒",
FlightNo: "航班号",
FlightNo_x: "航班号%a$",
FlightNumber: "按航班号",
FlightNumberHotelTrainNumber: "航班号/酒店/车次",
FlightPreference: "航班偏好",
FlightPricesChangeFrequently: "航班价格变动频繁，请尽快完成支付以免耽误您的出行",
FlightRank: "机票差标",
FlightRefundOrderConfirm: "机票退票订单确认",
FlightRefundTrip: "退票行程",
FlightRescheduleOrderConfirm: "申请改期订单确认",
FlightReschedulingOrRefundTips: "温馨提示：已办理值机手续的旅客需要先行取消值机，方可操作改期、退票。",
FlightSeatInfoArrow: "选座信息 >",
FlightsPassenger: "乘客",
FlightsRefunds: "国内机票退票",
FlightTicket: "机票",
FlightType: "航班类型",
Floor: "层",
Floor_1: "楼层",
Floor_x: "%a$层",
FlyingTime_x: "飞行 %a$",
FollowTheSystem: "跟随系统",
Foods: "餐品",
FoodTotalPrice: "商品总价",
ForgetPassword: "忘记密码",
ForgetPassword_1: "忘记密码?",
FormatIsIncorrect: "格式不正确",
Free: "快递 免邮",
FreeBoardband: "免费宽带",
FreeParking: "免费停车场",
FreePickUpService: "免费接机服务",
FreeWiFi: "免费WiFi",
FrequentContacts: "常用联系人",
FrequentContactsManagement: "常用联系人管理",
FrequentTraveler: "常旅客",
FrequentTravelerCardInformation: "常旅卡信息",
FrequentTravelerManagement: "常旅客管理",
FriendsareWaiting: "的小伙伴在恒顺商旅等你，用这个专属链接加入我们吧！",
From: "起",
Fuel: "燃油",
FuelFee: "燃油费",
FullPrice: "全价",
FullyBooked: "已订完",
FullyCallingTheVehicle: "正在全力呼叫车辆",
GBP: "英镑",
Gender: "性别",
GeneralAmenities: "服务设施",
GeneralCarUsage: "用车总概",
GeneralConditionsOfTransportation: "运输总条件",
GeneralTrainTicket: "火车票总概",
GetSMS: "获取短信",
GettingBoardingLocation: "正在获取上车地点",
GivenNames: "名(拼音)",
GivenNamesEg: "如：MEIMEI",
GoAndReturn: "去/返",
GoApprovals: "去审批",
GoBook: "去预订",
GoEnquiry: "去询价",
GoFlight: "去程航班",
Going: "去程",
GoingAndReturnTicket_x_x: "%a$(去程),%b$(返程)",
GoingDate: "去程日期",
GoingTicket_x: "%a$(去程)",
GoingTo: "将要去",
GoTag: "去",
GoToBind: "去绑定",
GoToChange: "去改期",
GoToHotel: "去酒店",
GoToMixedPay_x: "超标支付%a$",
GoToPay: "去支付",
GoToPay_1_x: "支付%a$",
GoToPay_x: "去支付%a$",
GoToQuery: "前往查询页面",
GoToRefund: "去退票",
GoToTheStoreBeforeOClock_x: "%a$之前到店",
Guarantee: "担保",
Guarantee_1: "需担保",
GuessYouWantToFindIt: "猜你想问",
GuestRoom_x: "房间%a$",
Guests: "入住人数",
Gym: "健身房",
HasProblem: "支付遇到问题",
HasQuitHumanCustomerService: "已退出人工客服",
HaveAgreement: "有协议价",
HaveQuestions: "有疑问? 可点击下方按钮进行咨询哦~",
HaveRefund: "有退票",
HaveRescheduled: "有改期",
HelpCenter: "帮助中心",
HengshunTravelAPP: "恒顺商旅APP",
HightoLow: "价格 高-低",
History: "历史记录",
HistoryOrHot: "历史/热门",
HistoryOrLocation: "当前/历史",
HistoryRecord: "历史记录(近1年)",
HKD: "港币",
HoldToTalk: "按住 说话",
Home: "首页",
Home_1: "家",
Homsom: "我的恒顺",
HOMSOM: "恒顺商旅",
HomsomEnterpriseCooperation: "恒顺商旅企业合作",
HomsomEnterpriseCooperationDesc: "如贵司已是恒顺商旅签约企业，请联系您的客户经理获取账号，请勿重复注册",
HomsomFWXY: "《服务协议》",
HomsomLoginPrivacyInfo: "已阅读并同意《服务协议》和《隐私政策》",
HomsomLoginPrivacyTitle: "请阅读并同意以下协议",
HomsomLoginPrivacyValue: "为了保障您的个人信息安全，使用登录功能需要您先阅读并同意《服务协议》和《隐私政策》",
HomsomLxd: "恒顺旅行豆",
HomsomPrivacyInfo: "《服务协议》和《隐私政策》",
HOMSOMScanningCodeLoginConfirmation: "恒顺商旅网扫码登录确认",
HomsomServiceFee: "恒顺服务费",
HomsomServiceFeeByChange: "恒顺改期服务费",
HomsomServiceFeeByIssue: "恒顺出票服务费",
HomsomSuccessDesc: "恒顺商旅已为您完成本次预订服务",
HomsomTools: "常用工具",
HomsomUpdateToExperience: "恒顺商旅又有更新了，快来抢先体验吧",
HomsomYSZC: "《隐私政策》",
Hot: "热门",
Hot_1: "热门兑换",
HotChina: "国内热门",
Hotel: "酒店",
Hotel_x: "%a$酒店",
HotelAddress: "酒店地址",
HotelBedNeeds: "床型要求",
HotelBookPermission: "酒店预订权限",
HotelBookWarn: "请您在提交订单前仔细阅读《恒顺商旅酒店预订条款》《个人信息授权声明》《供应商预订条款》",
HotelBookWarn_1: "《恒顺商旅酒店预订条款》《个人信息授权声明》《供应商预订条款》",
HotelChargeback: "酒店退单",
HotelCheckInInfo: "入住信息",
HotelCheckInPost_x: "%a$后入住",
HotelCheckOutBefore_x: "%a$前退房",
HotelConfirmEmailChineseOrEnglish: "·中/英版确认邮件（中/英版入住凭证pdf附件）",
HotelConfirmEmailChineseOrEnglishFlight: "·中/英版确认邮件（中/英版机票确认单pdf附件）",
HotelConfirmNumber: "酒店确认号",
HotelConfirmSMSChineseOrEnglish: "·中/英版确认短信",
HotelConsumptionInEachCity: "各城市酒店消费情况",
HotelDetails: "酒店详情",
HotelFeature: "酒店特色",
HotelGeneral: "酒店总概",
HotelGroupMembership: "酒店集团",
HotelGroupMembershipCard: "集团会员卡",
HotelGroupMembershipCard_1: "酒店集团会员卡",
HotelGroupMembershipCardBind: "绑定会员",
HotelGroupMembershipCardDetails: "会员卡详情",
HotelGroupMembershipCardInfo: "酒店集团会员卡信息",
HotelIntroduction: "酒店简介",
HotelInvoiceExplain: "注：房费发票由酒店开具，请到前台索取",
HotelLatestRoomPrice: "已为您获取最新酒店价格",
HotelName: "酒店名称",
HotelNight: "酒店总间夜",
HotelOtherNeeds: "其他要求",
HotelPayInStore: "到店支付",
HotelPromptIsMorning: "如需今天早晨6点前入住，请选择今天凌晨入住",
HotelRank: "酒店差标",
HotelRating: "酒店星(钻)级",
HotelRC: "酒店RC",
HotelReasonCodeDistribution: "酒店ReasonCode分布",
HotelReservationAuthority: "国内酒店差标",
HotelRestaurant: "酒店餐厅",
HotelRoomNumber: "房间数量",
HotelRoomRemarkInputTip: "如果有其他需求请填写，酒店会尽量为您安排",
Hotels: "的酒店",
HotelSelectChargebackGuest: "选择取消的入住人",
HotelService: "酒店服务",
HotelServicePreferences: "服务·喜好",
HotelServicePreferences_1: "服务喜好",
HotelSpecialNeeds: "特殊要求",
HotelSpecialNeedsEditHint: "如果有其他要求请填写，酒店会尽量为您安排。",
HotelSpecialNeedsExplain: "我们会把您的要求转达给酒店，但不保证酒店一定可以满足，如有特殊要求，请自行联系酒店。",
HotelTotalAmount: "酒店总金额",
HsTravelerExplainContext: "您已知晓您在恒顺商旅录入的常旅客身份信息，将用于您预订机票、火车票等所有需要实名制的产品，并在使用时进行验证，请确保此信息的真实有效，恒顺商旅将通过加密等方式保护此信息，且仅在有具体交易时授权提供给相关第三方。",
HsTravelerExplainTitle: "阅读并同意以下内容",
ID: "证件号",
IDCard: "身份证号",
IDCardFormatIsIncorrect: "身份证号码格式不正确!",
IDNumber: "证件号码",
IDType: "证件类型",
IfTheApproverIsInconsistent: "审批人不一致请分开下单",
IfTheRemainingSeats: "若剩余席位无法满足选座需求, 系统将自动为您分配席位",
IfTheRemainingSeatsCannotMeetTheSeatSelectionRequirements: "若剩余席位无法满足选座需求，系统将自动为您分配席位。",
IfTheTicketHasBeenIssuedTheLossMustBeBorneByTheOwner: "2.若已出票，退改损失需自行承担。",
IfYouCheckThisOption: "如果您勾选了此选项, 接下来的行程将视为您的私人行程",
IfYouHaveAnyQuestions: "如果您有任何疑问，请致电恒顺商旅全国服务热线：4006-123-123",
IfYouHaveAnyQuestionsPleaseContactYourBusinessConsultant: "如有疑问请联系您的业务顾问",
IfYouNeedToNarrowTheScopePleaseEnterMoreConditions: "若需缩小范围，请输入更多条件。",
IGotIt: "我知道了",
IHaveReadAndAgree: "已阅读并同意",
Import: "导入",
Import_1: "导 入",
ImportBusiness: "导入(因公)",
ImportFromFrequentTraveler: "从员工中导入",
In: "住",
IncludingHongKongMacauAndTaiwan: "含港澳台",
Income: "收入",
IncomeExpenditure: "收支明细",
InconsistentPasswordEnteredTwice: "两次输入的密码不一致",
IncorrectFormatOfContactMobileNumber: "联系人手机号格式不正确！",
Index_x: "%a$程",
Indexs: "字母索引",
InformationIsIncomplete: "基本信息不全",
InformationVerification: "信息验证",
Infrastructure: "基础设施",
InitiativeEnthusiasmEfficientMeticulousSpecialty: "更主动 更热情 更高效 更细致 更专业",
InnovativeService: "用心创新服务",
InputInformation: "输入信息",
InquiryInformationIsInvalid: "查询信息失效",
Install: "立即安装",
InsufficientRemainingTicketsChooseOtherFlight: "该舱位余票不足，请选择其他舱位",
Insurers: "保险",
Integral: "积分",
Integral_1: "商品积分",
IntegralByHomsom: "恒顺商旅积分",
IntegralMall: "积分兑换",
Intergral_x: "%a$积分",
IntergralDeliveryNumber_x: "快递单号：%a$",
IntergralDescription: "积分说明",
IntergralRules: "积分规则",
IntergralTotalColon: "合计：",
InternalProcurement: "内采",
International: "海外",
InternationalHotel: "海外酒店",
InternationalHotel_1: "海外酒店(不含港澳台地区)",
InternationalHotelOrderConfirmation: "海外酒店订单确认",
InternationalHotelOrders: "海外酒店订单",
InternationalRegion: "国际/中国港澳台",
InternationalRegion_1: "国际机票·港澳台",
InternationalTicket: "国际机票",
Internet: "上网",
InTheLocation: "定位中",
InTheTicket: "出票中",
Intl_1: "国际",
IntlDestination: "海外 目的地",
IntlFlightEnquiry: "国际机票需求单",
IntlFlightEnquiryService: "国际机票询价服务",
IntlFlightEnquiryServiceDesc: "人工询价，省时更省心",
IntlFlightOrder: "国际机票订单",
IntlFlightRank: "国际机票差标",
IntlFlights: "国际机票",
IntlFlights_1: "国际机票(含港澳台地区)",
IntlFlightsOrderConfirmation: "国际机票订单确认",
IntlHotel: "海外酒店",
IntlHotelReservationAuthority: "海外酒店差标",
InTravel: "出行中",
Invalid: "已失效",
Inventory_x: "库存：%a$件",
InvitationOtherType: "其他邀请方式",
InvitationStaff: "邀请员工",
Invitee: "邀请人",
InviteEmployeesToJoin: "通过下列任意方式邀请员工加入团队，管理员审核人员信息后，员工即可收到短信通知。",
Invoice: "发票",
InvoiceAmount: "开票金额",
InvoiceContent: "发票内容",
InvoiceDescription: "开票说明",
InvoiceDetais: "发票详情",
InvoiceFilling: "发票填写",
InvoiceFinished: "已开票",
InvoiceHeader: "发票抬头",
InvoiceHeaderName: "抬头名称",
InvoiceHeaderType: "抬头类型",
InvoiceInformation: "开票信息",
InvoiceInst: "开票说明",
InvoiceOperating: "开票中",
InvoiceParagraph: "发票税号",
InvoiceRemarks: "发票备注",
InvoiceTitle: "发票抬头",
InvoiceType: "发票类型",
InvoicingStatus: "开票状态",
InvoluntaryRefund: "非自愿退票",
InvoluntaryRefundDesc: "非自愿退票(航班取消/延误30分钟以上，以航空公司审核为准)",
InvoluntaryRefundExplain: "非自愿退票说明",
InvoluntaryReschedule: "非自愿改期",
InvoluntaryRescheduleDesc: "非自愿改期(航班取消/延误30分钟以上，以航空公司审核为准)",
InvoluntaryRescheduleExplain: "非自愿改期说明",
IsLoggedIn: "已登录",
IsNotAArrivalCity_x: "%a$不能作为到达城市",
IsNotADepartureCity_x: "%a$不能作为出发城市",
IsSendReceiveEmail: "接单邮件",
IsSendReceiveSms: "接单短信",
ItIsNowPastZeroOclock_x: "当前已过0点，如需凌晨%a$点前入住，请选“今天凌晨”入住",
JobDescription: "差标描述",
JPY: "日元",
JudgeCertificateExpired_x: "%a$当前证件有效期与起飞时间比较小于6个月，有可能会导致无法登机，是否继续？",
JustLookAtTheHighSpeedTrain: "只看高铁动车",
JustTime: "刚刚",
Keywords: "关键词",
KeywordSearch: "关键字搜索(酒店名)",
KeywordSearch_1: "位置/品牌/酒店名",
Kilometers: "行驶了%a$公里",
KPIAnalysis: "机票KPI分析",
KRW: "韩币",
LackOfIntegral: "积分不足",
Language: "多语言",
LanguageBeingSet: "正在设置语言...",
LanguageCH: "中文",
LanguageDefault: "默认语言",
LanguageEN: "英文",
LanguageSetting: "语言设置",
LastNameFormatIsIncorrect: "英文姓格式不正确！",
LatestArrivalDate: "最晚到账日期",
LateToEarly: "起飞时间 晚-早",
Latitude: "多纬度分析",
LearnAbout: "温馨提醒",
LearnMore: "了解超标付",
LengthMustNotExceedXDigits_x_x: "%a$长度不得超过%b$位",
LessThan10People: "10人以下",
LetYourFriendsAlsoDownloadHengshunTravelAPP: "让您的朋友也可以下载恒顺商旅APP",
LevelXAapproval_x: "%a$级审批",
Light: "浅色模式",
LimitedToXReservation_x: "仅限%a$预订",
LinkYourCellphone: "绑定手机",
List: "列表",
Loading: "加载中",
Loading_1: "正在刷新...",
LoadMoreTransit: "加载更多中转车次信息",
LocacleHotHotelRecommend: "当地热门酒店推荐",
LocalTax: "地方税",
LocalTime: "当地时间",
Location: "当前位置",
Location_1: "定位",
Location_2: "位置距离",
LocationFailed: "获取当前位置失败",
LocationNotTurnOn: "定位未开启",
LocationOfTheUnit: "单位所在地",
Login: "登录",
Login_1: "登 录",
Login12306: "登录12306",
Login12306AccountEnjoyExtremeReversionMemberPoints: "登录12306账号，享极致退改、会员积分",
LoginByAccountPassword: "账号密码登录",
LoginByVerificationCode: "验证码登录",
LoginByVerificationCodeEmail: "邮箱验证码登录",
LoginByVerificationCodeSMS: "短信验证码登录",
LoginConfirm: "确认登录",
LoginExpired: "登录失效，请重新登录!",
LoginName: "登录账号",
LoginOut: "退 出 当 前 账 号",
LoginSuccessful: "登录成功",
LoginTo12306ToImproveTheSuccessRateOfTicketIssuance: "登录12306账号提高出票成功率",
LogisticsCompany: "物流公司",
Logout: "退出",
LongPressSaveTheQRcode: "长按图片保存二维码",
LookAtThePriceExcludingTax: "看不含税价",
LookCertificate: "查看证明",
LookChargebackOrder: "查看退单",
LooOriginOrder: "查看原单",
Loss: "损失",
LossAnalysis: "损失分析",
LossPercentage: "损失占比",
LowCarbonHomsomPlantingHomsomForestTogether: "低碳恒顺, 共植恒顺林",
LowestPrice: "价格最低",
LowestPrice_1: "最低价",
LowestPriceDetails: "最低价详情",
LowestPriceHoursAndTicket_x_x: "前后%a$小时最低价 %b$/张",
LowestPriceTicketBookingRatio: "最低价票预订比例",
LowestTicketPrice: "机票最低价",
LowtoHigh: "价格 低-高",
LuggageStorage: "行李寄存",
Luxury: "豪华",
LuxuryHotel: "豪华型酒店",
LuxuryHotel_1: "豪华",
Lxd: "旅行豆",
LxdReward: "旅行豆奖励",
LxdReward_x: "%a$个旅行豆",
LxdRewardCompleteTheOrderToObtain: "完成订单后可获得",
LxdRules: "旅行豆规则",
LxdToCompleteTheOrder_x: "完成订单后可获得%a$个旅行豆",
MailSent: "邮件已发送",
MainFlight: "主航班",
MainRoomer: "主入住人",
MainRoomerRequired: "主入住人（必选）",
MakeSureToClearCache: "确定清除缓存？",
MakeSureToFingerprintLoginClose: "确定关闭指纹登录？",
MakeSureToLeaveYourCurrentAccount: "确定退出当前账户?",
Male: "男",
Manage: "管理",
Manage_x: "%a$管理",
Management: "管理",
Manager: "经办人",
ManualApproval: "人工审批",
ManuallyImport: "手工导单",
ManuallyPassed: "人工通过",
Map: "地图",
MapNavigation: "地图导航",
MapPointSelection: "地图选点",
MapSelection: "图选",
MapSurroundings: "地图/周边",
MarkAllMessagesAsRead: "将全部消息标记为已读?",
MatchingTheBestDriverForYou: "正在为你匹配最优司机",
MaximumFaceValue: "最高票面价",
MayBeAt: "可能在",
Meals: "有餐食",
MealsGoods: "用餐",
MealsOrder: "用餐订单",
MealsOrderConfirm: "用餐订单确认",
MealsRank: "用餐差标",
MealsRemarks: "用餐备注",
MeetingRoom: "会议室",
MembershipNumber: "会员卡号：",
Message: "消息",
MessageCenter: "消息中心",
MessageFailedToSend: "信息发送失败，请重试",
MessageSendSuccessfully: "信息发送成功",
MicrophoneIsRequiredForCurrentService: "当前服务需使用麦克风，请前往设置开启",
Midscale: "舒适",
Mileage: "里程数",
Minute: "分钟",
Minutes_x: "共花费%a$分钟",
MissedSavingAnalysis: "未预订最低价损失",
MixedPayment: "超标付",
MixedPaymentContent_x_x_x: "您预订的订单总金额%a$，差旅标准最高%b$，已超出%c$，您可以选择超标部分自行支付，或者选择全部公司支付。",
MixedPaymentDesc: "超差标部分自付，优质酒店超标付",
MixedPaymentDetails_x_x: "公司将支付%a$，需个人支付超出差旅标准金额%b$",
MixedPaymentOpen: "贵公司已开通超标付",
MixedPaymentOpenDesc: "自行支付超标部分即可享受更好的房间",
MixedPaymentSelfPayment: "超标部分自付",
MnlyOneMembershipCardCanBeAddedToTheSameHotel: "非常抱歉，同一家酒店只能添加一张会员卡。",
MobileCode: "手机区号",
MobileInvitationCode: "手机邀请码",
MobileInvitationCodeShare: "您将含有二维码的图片分享到公司社群，您的同事可以直接扫码申请",
MobileLinked: "手机号已绑定",
MobileUnlinked: "手机号未绑定",
ModifiedSuccess: "修改成功",
ModifiedSuccessfully: "修改成功! 新的手机号为",
Modify: "修改",
ModifyTheBoundPhone: "修改绑定手机",
Moments: "朋友圈",
Month: "月",
MonthApr: "4月",
MonthAug: "8月",
MonthDeb: "2月",
MonthDec: "12月",
MonthDetails: "本月明细",
MonthJan: "1月",
MonthJul: "7月",
MonthJun: "6月",
Monthly: "月度",
MonthlyExpense: "逐月消费",
MonthlyKPI: "逐月KPI",
MonthlySummary: "月度汇总",
MonthMar: "3月",
MonthMay: "5月",
MonthNov: "11月",
MonthOct: "10月",
MonthOnMonth: "环比",
MonthSep: "9月",
More_1: "更多",
More_2: "查看更多",
More_3: "查看<br/>更多",
MoreAvailable_x: "满%a$元可用",
MoreCabinsNoResult: "没有更多舱位了！",
MoreConsultation: "更多咨询",
MoreDate: "更多日期",
MoreDateB: "更多\n日期",
MoreDiner: "更多用餐人",
MoreFilterCriteria: "更多筛选条件",
MorePassengerFlight: "更多乘机人",
MorePassengerRoomer: "更多入住人",
MorePassengerVehicle: "更多乘车人",
MorePrice: "更多价格",
MoreSorting: "更多排序",
MoreThan100People: "100人以上",
MoreThanThePurchaseLimitCanNotBuy: "超过限购数量，无法购买",
MoreTraveler: "更多出行人",
Morning: "凌晨",
MorningCall: "叫醒服务",
MultipleAccountsHaveBeenBound: "您输入的手机号绑定了多个账号，请您尝试使用其他登录方式",
MultipleChoice: "多选",
MultipleChoiceSuccessRate: "多选提高成功率",
MultiTierReschedulingNotSupported: "不支持多个行程改期",
MustBeAccompaniedByAnAdultOnBoard: "儿童/婴儿需由成人陪同乘机",
MyAdvancePayment: "我的预付款",
MyApplyTravel: "我的出差申请",
MyApprovals: "待我审批",
MyBalance: "我的余额",
MyCollection: "我的收藏",
MyCustomerService: "我的客服",
MyInitiations: "我发起的",
MyIntergral: "我的积分",
MyLxd: "我的旅行豆",
MyTrip: "我的行程",
Name: "姓名",
Name_x: "%a$名称",
NameFillingInstructions: "姓名填写说明",
Nation: "国家/地区",
NationalityOfThePerson: "入住人国家/地区",
NationalServicePhone: "全国服务热线",
Near: "邻近",
NearExpiration: "临近过期",
NearlyOneYear: "近1年",
NetList: "网单",
NetTransactionPrice: "成交净价",
Network: "网络",
NetworkRequestConnectionFailed: "网络连接失败",
NetworkRequestFailed: "网络请求失败",
New: "新增",
New_1: "新 增",
New_x: "新增%a$",
NewCertificate: "添加证件",
NewContactSuccessFailed: "新增联系人失败",
NewContactSuccessFul: "新增联系人成功",
NewDepartment: "新增部门",
NewDiner: "新增用餐人",
NewFlightDate: "新航班日期",
NewFrequentTravelCard: "添加常旅卡",
NewHotelGroupMembershipCard: "添加酒店集团会员卡",
NewMobile: "新的手机号",
NewPassenger: "新增乘客",
NewPassengerFlight: "新增乘机人",
NewPassengerVehicle: "新增乘车人",
NewRC: "新增RC",
NewRoomer: "新增入住人",
NewTraveler: "新增出行人",
NewVersion: "使用新版",
NewVersionFound: "发现新版本",
NextStep: "下一步",
NextStep_1: "下 一 步",
NickName: "昵称",
NightsTotalPrice_x: "%a$晚总价",
NoApprovalRequired: "无需审批",
NoAvailableCabin: "暂无可预订舱位",
NoAvailableFlight: "暂无可预订航班",
NoAvailableFlightDesc: "没有找到符合条件的航班，您可以更改筛选项试试~",
NoAvailableVouchersForUse: "暂无可用抵用券",
NoBreakfast: "无早",
NoChangeablePassengers: "无可改签的乘客",
NoChildUnderDepartmentsAtPresent: "当前部门下暂无子部门",
NoCollection: "暂无收藏",
NoData: "暂无结果",
NoData_1: "暂无数据",
NoDataTravelStandard: "暂无相关差标信息",
NoIncludedChangeFeeTag: "(未包含改期费)",
NoMeal: "无餐食",
NoMore: "没有更多了",
NoMoreCredential: "暂时没有更多证件了",
NonBookable: "不可订",
None: "无",
NoNewNotice: "没有新通知",
NoNews: "暂无消息",
NonStaff: "外部人员",
NonStaffDetail: "外部人员详情",
NonStaffManagement: "外部人员管理",
Noon: "中午",
NoOrder: "暂无订单",
NoPermissions: "没有权限",
NoPermissionToAccess: "没有权限访问",
NoPlatformAvailablePleaseContactYourTravelConsultant: "暂无可用平台，请联系您的差旅顾问",
NoRemainingTickets: "暂无余票, 不可预订",
NoReschedulingReeIsIncluded: "未包含改期费",
NoResult: "没有结果",
NormalSeat: "商务座",
NoStay: "未入住",
NotBook: "不可预订",
NotBooking_1: "不 可 预 订",
NotCertificateInfo: "暂无证件信息",
NotesOnRefundAndChangeOfVisa: "退改签注意事项",
Nothing: "暂无",
Notice: "通知",
NoticeCanceled: "通知取消",
NoTicket: "无票",
NotInitiated: "未发起",
NotNecessaryChoose: "非必选",
NotNecessaryChoosePleaseChoose: "请选择",
NotNecessaryChooseUneditable: "不可编辑",
NotNecessaryFill: "非必填",
NotNecessaryFillPleaseFill: "请填写",
NotNecessaryFillPleaseFillOrChoose: "请填写或选择",
NotNecessaryFillUneditable: "不可编辑",
NotNeedDepartPassenger: "没有需要出发的乘客",
NoTravelCard: "暂无常旅卡信息",
NotRefundable: "不可退票",
NoTrip: "当前您还没有任何行程信息",
NotSelectable: "不可选择",
NotSendEmptyInfo: "不可发空白消息",
NotUsed: "不使用",
Now: "现在",
NoXSegment_1_x: "选第%a$程",
NoXSegment_2_x: "选为第%a$程",
NoXSegment_x: "第%a$程",
Number: "人数",
Number_1: "数量",
NumberOfAdult: "总成人数",
NumberOfChild: "总儿童数",
NumberOfCustomItems: "自定义项数",
NumberOfFullPriceTickets: "全价票张数",
NumberOfGuestsPerRoom: "每间房入住成人数",
NumberOfRoom: "间数",
NumberOfRooms: "房间数",
NumberOfRoomsAndOccupancy: "房间数量及入住人数",
NumberOfRoomsBooked: "预订房间数",
NumberOfSheets: "张数",
NumberOfTrainTickets: "火车票张数",
NumberOfWorkers: "员工数量",
NZD: "新西兰元",
OffAndBelow: "折及以下",
OK: "知道了",
OldAndNewPasswordsMustNotBeTheSame: "新旧密码不得相同",
OldVersion: "使用旧版",
OneAirlineCanOnlyAddOneFrequentTravelCard: "非常抱歉，同一家航空公司只能添加一张常旅卡。",
OneWay: "单程",
OnlineChangeNotSupported: "暂不支持在线改签",
OnlineCheckIn: "在线值机",
OnlineFlight: "值机",
OnlineFlight_1: "去值机",
OnlineFlightWarn: "温馨提示：网上值机服务由航空公司提供，您办理值机产生的任何问题请直接与航空公司联系。",
OnlinePayment: "在线支付",
OnlinePayment_1: "在线付",
OnlineReport: "在线管理报告",
OnlineService: "在线客服",
OnlyAdultTicketBookingsAreSupported: "仅支持成人票预订",
OnlyAlternativeTrainsCanBeSelectedAtMost_x: "最多只能选择%a$个备选车次",
OnlyChineseName_x: "%a$只能使用中文名",
OnlyDirectFly: "仅看直飞",
OnlyEnglishName_x: "%a$只能使用英文名",
OnlySeeSelected: "仅看已选",
Open: "开通",
Open_1: "去开启",
Open_2: "打开",
OpenHengshunTraveMobilelEditionForBetterExperience: "打开恒顺商旅移动版，使用体验更佳",
Opening: "开通",
OpenSafari: "Safari打开",
OpenSuccess: "开通成功",
OpenYears: "开业时间",
OperatedBy: "实际承运",
Operating: "处理中",
Operation: "操作",
Operator: "处理人",
Optional: "可选",
OptionalCities: "可选城市",
Order: "订单",
Order_1: "预约",
Order_2: "订单问题",
Order_3: "兑换记录",
OrderCancelFailed: "订单取消失败",
OrderCancelled: "订单已取消",
OrderCompleted: "订单已完成",
OrderDate: "下单日期",
OrderDateBookTime: "下单时间",
OrderDetails: "订单详情",
OrderDetailsBusiness: "订单详情(因公)",
OrderDetailsPersonal: "订单详情(因私)",
OrderFilling: "订单填写",
OrderInformation: "订单信息",
OrderInformationForChange: "改期订单信息",
OrderInformationForRefund: "退票订单信息",
OrderIntergral: "订单积分",
OrderNo: "订单编号",
OrderNo_1: "兑换编号",
OrderNoCannotBeEmpty: "订单号不能为空",
OrderNotice: "订单通知",
OrderNoticeDesc: "出票状态、预订状态提醒",
OrderNumber: "订单号",
OrderNumber_1: "编号",
OrderNumber_x: "订单号%a$",
OrderPaid: "订单已支付",
OrderStatus: "订单状态",
OrderStatusHasChangedPleaseReOperate: "订单状态发生变化，请重新操作",
OrderSubmissionSuccessFailed: "订单提交失败",
OrderSubmissionSuccessful: "订单提交成功！",
OrderSynTime: "同步时间",
OrderTime: "订单时间",
OrderType: "订单类型",
OrganizationalStructure: "组织架构",
Original: "原",
OriginalPrice: "原价",
OriginalSchedule: "原订行程",
OriginFlightOrderNo: "原航班订单号",
OriginFlights: "原航班",
OriginOrderNumber: "原订单",
Other: "其他",
Other_x: "其他: %a$",
OtherInfo: "其他信息",
OtherInformation: "其他信息",
OtherLoginMethods: "其他登录方式",
OtherPolicy: "其他规定",
OtherReasons: "其他原因",
OurAgreedAveragePrice: "我司协议均价",
OurCompany: "我司",
OurExclusiveConsultantWillContactYouAsSoonAsPossible: "我们的专属顾问会尽快联系您",
Out: "离",
OverseasMultiCity: "多程",
OverseasServicePhone: "境外服务热线",
Overview: "总概",
OverviewOfTickets: "机票总概",
P: "私",
PackUp: "收起",
Page: "页",
PaidBoardband: "收费宽带",
PaidParking: "收费停车场",
PaidPickUpService: "收费接机服务",
PaidWiFi: "收费WiFi",
PartialChange: "部分改签",
PartialRefund: "部分退票",
Pass: "通过",
Passenger: "乘客",
PassengerDetails: "乘客详情",
PassengerFlight: "乘机人",
PassengerFlightDetails: "乘机人详情",
PassengerFlightName: "乘机人姓名",
PassengerInformation: "乘客信息",
PassengerName: "乘客姓名",
PassengersWhoNeedToRefund: "需要退票的乘机人",
PassengersWhoNeedToReschedule: "需要改期的乘机人",
PassengersWithoutRefundableTickets: "无可退票的乘客",
PassengerVehicle: "乘车人",
PassengerVehicleDetails: "乘车人详情",
PassengerVehicleName: "乘车人姓名",
Password: "密码",
Password12306: "12306密码",
PasswordCanBeResetViaEmail: "可通过已绑定的邮箱地址重设密码",
PasswordCanBeResetViaPhone: "可通过已绑定的手机号+短信验证码重设密码",
PasswordMustBeLettersAndNumbers: "必须为8-20位字母和数字",
PasswordMustBeNoLessThanEightDigitsInLength: "密码长度不得小于8位",
PasswordMustBeNoLessThanSixDigitsInLength: "密码长度不得小于6位",
PasswordRuleDescription: "建议密码由大小写字母和数字组成，且长度大于8位",
PasswordsMustContainLettersAndNumbers: "密码必须包含字母和数字",
PasswordUpdateSuccessful: "密码修改成功",
Pay: "支付",
PayAgain: "重新支付",
PayAtTheStore: "到店付",
PayAtTheStore_x: "到店付%a$",
PayAtTheStoreAbout: "到店支付 约",
PayAtTheStoreAbout_x: "到店支付 约 %a$",
PayAtTheStoreTaxesFees_x: "到店另付税/费 约%a$",
PayBackTips: "订单尚未支付，返回将放弃支付，您可以在订单中查看并继续支付!",
PayInformation: "支付信息",
PayInformationDetails: "支付详情",
PaymentFailed: "支付失败",
PaymentOnBehalfShareDesc: "发给他人帮你付款吧~",
PaymentPermission: "支付权限",
PaymentSerialNumber: "支付流水号",
PaymentSuccessful: "支付成功",
PaymentSuccessfulContinueToPayTheNextOrder: "支付成功，继续支付下一个订单",
PaymentTime: "支付时间",
PaymentWay: "支付方式",
PayNow: "立即支付",
PayOrder: "支付订单",
PayTypeCompanyDesc: "由公司账户支付费用",
PayTypeMixedDesc: "超出差旅标准的费用需个人支付",
PayTypePersonalDesc: "需个人支付费用",
PdfDownload: "PDF下载",
PendForOperating: "待处理",
Pending: "待审批",
Pending_1: "申请已提交(待审批)",
Pending_2: "预订成功(待审批)",
Pending_3: "申请已提交",
Pending_4: "待审核",
Pending_x: "待审核(%a$)",
People_x: "%a$人",
People10: "10-20人",
People20: "20-40人",
People40: "40-100人",
PeoplePerRoom: "每间入住",
PeopleStaying_x: "%a$人入住",
PercentageOfPotentialSavings: "潜在节省占比",
PercentageOfRoomNights: "间夜占比",
PercentageOfSavings: "节省占比",
PercentageOfSheets: "张数占比",
PermissionsRange: "权限范围",
Person: "个人",
PersonAccount: "会员账号",
Personal: "因私",
Personal_x: "%a$(因私)",
PersonalInformation: "个人信息",
PersonalPayment: "个人支付",
PersonalPermissions: "个人权限",
PersonalTools: "个人工具",
PersonalTravelInformation: "个人差旅信息",
PersonalTrip: "因私出行",
PersonalTrip_1: "私人出行",
PersonalWallet: "个人钱包",
PersonalWorkbench: "个人工作台",
PersonEntry: "会员入口",
PhoneNumberFormatIsIncorrect: "号码格式不正确！",
PhoneNumberFormatIsIncorrect_1: "手机号码格式不正确！",
PhoneNumberFormatIsIncorrect_x: "%a$的手机号格式不正确",
PhoneNumberOrEmailFormatIsIncorrect: "手机号/邮箱的格式不正确",
Photo: "照片",
PhotoOrFile: "照片/文件",
PickUpUseCarTimeTenMinute: "落地后10分钟",
PickUpUserCarTimeFiftyMinute: "落地后50分钟",
PickUpUserCarTimeFourtyMinute: "落地后40分钟(有行李托运推荐)",
PickUpUserCarTimeSixtyMinute: "落地后60分钟",
PickUpUserCarTimeThirtyMinute: "落地后30分钟(无行李托运推荐)",
PickUpUserCarTimeTwentyMinute: "落地后20分钟",
Picture: "图片",
PlaceOfDeparture: "出发地点",
PlaceOfService: "送达地点",
Plan: "原计划",
Plan_x: "原计划%a$",
Plane: "机型",
PlaneBookNoticeCopyTips: "已复制链接到剪切板，小程序暂不支持预览，请在浏览器打开",
PlaneConstruction: "机建",
PlaneConstructionFee: "机建费",
PlaneConstructionFuel: "机建 / 燃油",
PleaseAddARoomer: "请添加入住人",
PleaseAddContact: "请添加联系人",
PleaseCheckInAccordingToTheActualFlight: "请按实际乘坐航班办理值机。",
PleaseCheckTheBoxToAgreeAfterReadingTheRelevantTermsAndConditions: "请阅读相关条款后勾选同意",
PleaseChoose: "请选择",
PleaseChoose_1: "请选择",
PleaseChooseAPlaceOfArrival: "请选择送达地点",
PleaseChooseAPlaceOfDeparture: "请选择出发地点",
PleaseChooseApprover: "请选择审批人",
PleaseChooseASeat: "请选座",
PleaseChooseCarType: "请选择车型",
PleaseChooseContacts: "请选择联系人",
PleaseChooseIDType: "请选择证件类型",
PleaseChooseOrInput_x: "请选择/填写%a$",
PleaseChoosePayWay: "请选择支付方式",
PleaseChooseRouteArrivalCity_x: "请选择第%a$程到达城市",
PleaseChooseRouteDepartCity_x: "请选择第%a$程出发城市",
PleaseChooseRouteGoDate_x: "请选择第%a$程出发时间",
PleaseChoosesCabinLevel: "请选择舱位等级",
PleaseChooseTheAirClub: "请选择航司俱乐部",
PleaseChooseTheDocumentSignatory: "请选择证件签署国",
PleaseChooseTheHotelGroupMembership: "请选择酒店集团",
PleaseChooseTheReason: "请您选择原因",
PleaseChooseTheReason_1: "请您选择原因:",
PleaseChooseTheValidityPeriodOfTheCertificate: "请选择证件有效期",
PleaseChooseWhenToUseTheCar: "请选择用车时间",
PleaseChooseYourNationality: "请选择国家/地区",
PleaseCompleteTheInformation_x: "请补全%a$的信息",
PleaseCompleteTheReceiptInformation: "请完善收货信息",
PleaseCompleteTheVerificationAsSoonAsPossible: "请您尽快完成核验，以免影响出票",
PleaseCompleteYourGivenNames: "请完善您的英文名",
PleaseCompleteYourNameInformation: "请完善您的姓名信息",
PleaseCompleteYourSegments: "请完善您的行程",
PleaseCompleteYourSurname: "请完善您的英文姓",
PleaseConfigureTheExaminerInTheBackground: "请后台配置审批人",
PleaseConfirmIfWeChatPaymentHasBeenCompleted: "请确认微信支付是否已完成",
PleaseConfirmTheNewPassword: "请确认新密码",
PleaseContactTheAccountNumber12306: "请关联12306账号",
PleaseDoNotRefreshThisPagePromptOperation: "请勿刷新本页面，按手机提示操作",
PleaseEnter: "请输入",
PleaseEnter_1: "请输入要查找的",
PleaseEnter_1_x: "请输入要查找的%a$",
PleaseEnter_x: "请输入%a$",
PleaseEnterAccurateAndCompleteUnitInformation: "请填写准确完整的单位信息",
PleaseEnterAComment: "请输入备注信息",
PleaseEnterACostCenterName: "请填写成本中心",
PleaseEnterAddress: "请输入详细地址",
PleaseEnterANewPassword: "请输入新密码",
PleaseEnterASuggestionOfNoLessThanEightWords: "请输入不少于8个字的建议",
PleaseEnterAUsername: "请输入登录账号",
PleaseEnterAuthorizationCode: "请输入授权码",
PleaseEnterBindingCredentialName: "请输入要绑定的证件号",
PleaseEnterBindingCredentialType: "请选择要绑定的证件类型",
PleaseEnterBindingMobile: "请输入要绑定的手机号",
PleaseEnterBindingUserName: "请输入要绑定的名字",
PleaseEnterCompnayInfo: "请输入企业名称",
PleaseEnterContactName: "请输入联系人",
PleaseEnterCorrectEmail: "请输入正确的邮箱",
PleaseEnterCorrectMobile: "请输入正确的手机号",
PleaseEnterEheCorrectFormatOfValidityPeriod: "请输入正确的信用卡有效期格式",
PleaseEnterIDNumber_x: "请输入%a$证件号码",
PleaseEnterInformation: "请输入信息",
PleaseEnterKey: "请输入要查找的关键字",
PleaseEnterMobileVerification: "请输入手机验证码",
PleaseEnterPhone: "请输入收货人手机号",
PleaseEnterPhoneNumber_x: "请输入%a$手机号",
PleaseEnterPhoneNumberOrEmail: "请输入手机号/邮箱",
PleaseEnterRecipient: "请输入收货人姓名",
PleaseEnterRegisterInfo: "请输入注册信息",
PleaseEnterSMSCode: "请输入短信验证码",
PleaseEnterThe_x: "请输入要查找的%a$",
PleaseEnterTheAccountForRetrievePassword: "请先输入要找回密码的账号",
PleaseEnterTheAccountInformationYouNeedToRetrieveYourLoginPassword: "请输入你需要找回登陆密码的账户信息",
PleaseEnterTheAirport: "请输入要查找的机场",
PleaseEnterTheArriveAddress: "请输入下车地点",
PleaseEnterTheBindingEmail: "绑定的邮箱",
PleaseEnterTheBindingPhone: "绑定的手机号",
PleaseEnterTheBindingPhoneNumberEmail: "请输入绑定的手机号/邮箱",
PleaseEnterTheBindingPhoneNumberEmail_1: "绑定的手机号/邮箱",
PleaseEnterTheCardNumber: "请输入卡号",
PleaseEnterTheCardNumberOrUsername: "请输入卡号或用户名",
PleaseEnterTheCarRank: "请输入要查找的用车差标",
PleaseEnterTheCity: "请输入要查找的城市",
PleaseEnterTheCityNameToSearch: "请输入城市名进行搜索",
PleaseEnterTheCompanyAddress: "请输入公司地址",
PleaseEnterTheCorrectAccountPassword: "请输入正确的账号密码",
PleaseEnterTheCorrectCreditCardNumber: "请输入正确的信用卡号",
PleaseEnterTheCorrectMobilePhoneNumberEmail: "请输入正确的手机号/邮箱",
PleaseEnterTheCorrectVerificationCode: "请输入正确的验证码",
PleaseEnterTheCostCenter: "请输入要查找的成本中心",
PleaseEnterTheCountry: "请输入要查找的国家/地区",
PleaseEnterTheCurrentPassword: "请输入当前密码",
PleaseEnterTheCustomItems: "请输入要查找的自定义项",
PleaseEnterTheDepartAddress: "请输入上车地点",
PleaseEnterTheDepartment: "请输入要查找的部门",
PleaseEnterTheFlightBookPermission: "请输入要查找的机票预订权限",
PleaseEnterTheHomeAddress: "请输入家庭地址",
PleaseEnterTheHotelBookPermission: "请输入要查找的酒店预订权限",
PleaseEnterTheHotelCity: "城市/地标/酒店名",
PleaseEnterTheIntlHotelCity: "城市/酒店名",
PleaseEnterTheLastThreeDigitsOfTheCVVSecurityCode: "请输入CVV安全码后三位",
PleaseEnterTheName: "请输入要查找的姓名",
PleaseEnterTheNameOfThePerson: "请输入入住人姓名或订单号",
PleaseEnterTheNameOfThePerson_1: "入住人姓名或订单号或酒店名称",
PleaseEnterTheNameOfTheRank: "请输入要查找的差标名称",
PleaseEnterTheNameOfTheRole: "请输入要查找的角色名称",
PleaseEnterTheNewPassword: "必须为8-20位字母和数字",
PleaseEnterTheOldPassword: "请输入原密码",
PleaseEnterThePassengerName: "请输入乘客姓名或订单号",
PleaseEnterThePassword: "请输入登录密码",
PleaseEnterTheSystemRole: "请输入要查找的系统角色",
PleaseEnterTheTemplate: "请输入要查找的审批模板名称",
PleaseEnterTheTrainAccountNewPassword: "6-20位字母和数字",
PleaseEnterTheTrainBookPermission: "请输入要查找的火车票预订权限",
PleaseEnterTheTrainStation: "请输入要查找的火车站",
PleaseEnterTheTraveler: "请输入要查找的姓名/邮箱/手机号/员工编号",
PleaseEnterTheTraveler_1: "搜索姓名/邮箱/手机号/员工编号/昵称",
PleaseEnterTheTraveler_2: "搜索姓名/手机号/证件号",
PleaseEnterTheUnitCodeOrUnitShortName: "请输入单位编号/简称/全称",
PleaseEnterTheValidityPeriodOfTheCreditCard: "请输入信用卡有效期",
PleaseEnterYourAccountInformation: "请输入账号信息",
PleaseEnterYourExceedReason: "请填写超标原因",
PleaseEnterYourNewPasswordAgain: "请再次输入新密码",
PleaseFill: "请填写",
PleaseFill_x: "请填写%a$",
PleaseFill_x_x: "请填写%a$的%b$",
PleaseFillBudget: "请填写预算",
PleaseFillBudget_x: "请填写%a$的预算",
PleaseFillIn: "请填写新的手机号。",
PleaseFillIn_x: "请输入%a$名称",
PleaseFillInCardNumber: "请填写卡号",
PleaseFillInCostCenterName: "请输入成本中心",
PleaseFillInDepartmentName: "请输入部门名称",
PleaseFillInDiner: "请填写用餐人姓名",
PleaseFillInEmail_x: "请填写%a$的邮箱",
PleaseFillInFirstName: "请填写英文名",
PleaseFillInGuestName: "请填写入住人姓名",
PleaseFillInIDNumber: "请填写证件号",
PleaseFillInLastName: "请填写英文姓",
PleaseFillInMobile_x: "请填写%a$的手机号",
PleaseFillInName: "请填写姓名",
PleaseFillInNewPhoneCurrentPhone: "请填写新的手机号。当前手机号: ",
PleaseFillInOrderNumber: "请填写订单号",
PleaseFillInPassengerName: "请填写乘客姓名",
PleaseFillInPhoneNumber: "请填写手机号",
PleaseFillInRC: "请输入RC",
PleaseFillInTheContactMobilePhoneNumber: "请填写联系人手机号",
PleaseFillInTheContactMobilePhoneNumber_x: "请填写联系人%a$的手机号",
PleaseFillInTheReasonForRefund: "请填写退票原因",
PleaseFillInTheReasonForRescheduling: "请填写改期原因",
PleaseFillInTheShippingAddress: "请填写配送地址",
PleaseFillInVerificationCode: "请填写验证码",
PleaseFillOrChoose_x: "请填写或选择%a$",
PleaseFillTotalBudget: "请填写总预算",
PleaseGetTheVerificationCode: "请先获取验证码",
PleaseHoldDownTheSliderAndDrag: "请按住滑块，拖动到最右",
PleaseHoldThePhoneVertically: "请垂直紧握手机",
PleaseInputCreditCardNumber: "请输入信用卡号",
PleaseInputYourDestination: "请输入您的目的地",
PleaseMakeSureYouHaveEnoughTimeToCheckIn: "1.购票前请先到值机柜台确认出票后仍有时间值机。",
pleaseOpenScanOnAlipayScanQRCodeToPay: "请打开手机支付宝的扫一扫，扫描二维码支付",
pleaseOpenScanOnWeChatScanQRCodeToPay: "请打开手机微信的扫一扫，扫描二维码支付",
PleasePressBackOnceToExit: "请在按一次返回退出",
PleaseReadAndAgreeTo: "请先阅读并同意《账户授权协议》",
PleaseReadAndAgreeTo_x: "请先阅读并同意%a$",
PleaseReadAndTickTheAgreementFirst: "请先阅读并勾选协议",
PleaseReselectYourCheckInDate: "请重新选择入住日期",
PleaseSelect_x: "请选择%a$",
PleaseSelect_x_x: "请选择%a$的%b$",
PleaseSelectADepartureDate: "请选择出发日期",
PleaseSelectADestination: "请选择目的地",
PleaseSelectAirport: "请选择机场",
PleaseSelectApprover: "请选择审批人",
PleaseSelectArea: "请选择所在地区",
PleaseSelectArrival: "请选择到达地址",
PleaseSelectArrivalAirport: "请选择到达机场",
PleaseSelectArrivalCity: "请选择到达城市",
PleaseSelectArrivalTrainStation: "请选择到达火车站",
PleaseSelectAtLeastOneCityOverseas: "至少选择一个国际城市/港澳台",
PleaseSelectAtLeastOneOccupant: "请至少选择1位入住人",
PleaseSelectBackDate: "请选择返程日期",
PleaseSelectBusinessTravelScale: "请选择企业差旅规模(月度)",
PleaseSelectCertificate: "请选择证明",
PleaseSelectCertificateNo: "请输入证件号",
PleaseSelectCostCenter: "请选择成本中心！",
PleaseSelectDepartment: "请选择部门！",
PleaseSelectDeparture: "请选择出发地址",
PleaseSelectDepartureAirport: "请选择出发机场",
PleaseSelectDepartureTrainStation: "请选择出发火车站",
PleaseSelectDestination: "请选择城市",
PleaseSelectDiner: "请选择用餐人",
PleaseSelectedTheCheckInDate: "请选择入住日期",
PleaseSelectFlightChangeDate: "请选择航班改期日期",
PleaseSelectFlightChangeDate_x: "请选择%a$航班改期日期",
PleaseSelectFlightDate: "请选择航班日期",
PleaseSelectFlightNumber: "请填写航班号",
PleaseSelectGuest: "请选择入住人",
PleaseSelectGuestLeastOne: "每间房间必须填写至少一位入住人",
PleaseSelectHotelArrivalTime: "请选择到店时间",
PleaseSelectLoginTypeWitchAnytime: "请选择以下任一入口登录",
PleaseSelectOrFillInMealsRemarks: "请选择/填写用餐备注",
PleaseSelectOrFillInPurpose: "请选择/填写差旅目的",
PleaseSelectOrFillInTheReasonForCancelReason: "请选择/填写取消原因",
PleaseSelectOrFillInTheReasonForExceedingTheStandard: "请选择/填写超标原因",
PleaseSelectOrFillInTheReasonForRefundReason: "请选择/填写退票原因",
PleaseSelectOrFillInTheReasonForReschedulingReason: "请选择/填写改期原因",
PleaseSelectOrFillInTheReasonForViolatingTheTravelRank: "请选择/填写违反差旅标准原因",
PleaseSelectOrFillInTheReasonOrPleaseSelectTheMixedPayment: "请选择/填写超标原因或选择超标付",
PleaseSelectPassenger: "请选择乘客",
PleaseSelectPassengersFirst: "请先选择乘客",
PleaseSelectPaymentWay: "选择支付方式",
PleaseSelectPurpose: "请选择差旅目的",
PleaseSelectSeats: "请选择座位",
PleaseSelectTheCardExpirationMonth: "请选择月份",
PleaseSelectTheCardExpirationYear: "请选择年份",
PleaseSelectTheDateOfBirth: "请选择出生日期",
PleaseSelectTheDepartureCity: "请选择出发城市",
PleaseSelectTheFavoriteToDeleteFirst: "请先选择需要删除的收藏",
PleaseSelectTheFirstSeat: "请选择第一程座位",
PleaseSelectTheFollowingMethodsForVerification: "请选择以下方式进行核验",
PleaseSelectTheItineraryToBeRescheduled: "请选择要重新安排的行程",
PleaseSelectTheMixedPayment: "请选择超标付继续预订",
PleaseSelectThePassengersToBeChanged: "请选择需改签的乘客",
PleaseSelectThePassengerWhoNeedsToChange: "请选择需要改签的乘客",
PleaseSelectThePassengerWhoNeedsToRefund: "请选择需要退票的乘客",
PleaseSelectThePassengerWhoNeedsToRefund_x: "请选择需要退票的%a$",
PleaseSelectThePassengerWhoNeedsToReschedule: "请选择需要改期的行程",
PleaseSelectTheRoomAndRoomer: "选择客房和入住人",
PleaseSelectTheSecondSeat: "请选择第二程座位",
PleaseSelectTheShippingAddress: "请选择收货地址",
PleaseSelectTheTravelApplication: "请选择出差申请单",
PleaseSelectTheTypeOfQuestion: "请选择问题类型",
PleaseSelectTrainStation: "请选择火车站",
PleaseSelectTraveler: "请选择出行人",
PleaseSendTheAbovePaymentTo: "烦请将以上款项于",
PleaseSure: "请务必确认",
PleaseUploadAttachFile_x: "请上传%a$",
PleaseUseTheFollowingCreditCard: "温馨提示：请使用以下信用卡",
PleaseVerifyThatYouHaveAFaceIDForLogin: "请验证已有面容ID，用于登录",
PleaseVerifyThatYouHaveATouchIDForLogin: "请验证已有指纹ID，用于登录",
PointYourFaceAtTheFrontCameraOfYourPhone: "请将脸部对准手机前置摄像头",
PoliciesServices: "政策与服务",
Policy_1: "政策",
PolicyAddress: "保单地址",
PolicyNoColon: "保单号：",
PopularDestinations: "热门目的地",
PopularFilters: "热门筛选",
PopularRoutes: "热门线路",
Pptimization: "最优方案",
Precautions: "注意事项",
Predict: "预计",
Preferential: "优惠",
Preferential_1: "本单可享",
PremiumHotel: "高档型酒店",
PremiumHotel_1: "高档",
Prepay: "预付",
Prepayments: "预付款",
PreQuantifier: "/人",
PressVoice: "按住录音",
PreviewNotSupportedAndIsCopy: "暂不支持预览，已复制到剪切板，请在浏览器中打开",
Price: "价格",
Price_1: "参考票价",
Price_2: "星级价格",
Price150: "¥150以下",
Price150_300: "¥150-300",
Price300_450: "¥300-450",
Price400: "¥400以下",
Price400_700: "¥400-700",
Price450_600: "¥450-600",
Price600_1000: "¥600-1000",
Price700_1000: "¥700-1000",
Price1000_1300: "¥1000-1300",
Price1300_1800: "¥1300-1800",
PriceCeiling: "价格上限",
PriceDetails: "价格明细",
PriceHighest: "从高到低",
PriceHighToLow: "价格高-低",
PriceHotel: "房价",
PriceLowest: "从低到高",
PriceLowToHigh: "价格低-高",
PriceOfPerson_x_x: "%a$ X %b$人",
PriceOver1000: "¥1000以上",
PriceOver1800: "¥1800以上",
PricePerPerson_x: "%a$/人",
PriceRating: "价格/星级",
PriceRating_1: "价格/钻级",
PriceSingleChoice: "价格(单选)",
Print: "打印",
PriorityHighPrice: "高价优先",
PriorityHighRating: "高星优先",
PriorityLowPrice: "低价优先",
PrivacyPolicy: "隐私政策",
Processing: "未处理",
ProcessingCompleteTime: "处理完成时间",
ProcessingTime: "处理时间",
ProduceDesc: "产品描述",
ProduceName: "产品名称",
Product: "产品",
ProductDesc: "产品说明",
ProductExperience: "产品体验",
ProductIntroduction: "商品介绍",
ProductOverview: "商品概述",
ProductUseRuleDes: "使用规则",
Progress: "进行中",
PromptDateHotel: "您选择的是酒店当地日期",
PromptDatePrice: "所选日期为出发地日期，价格变动频繁，以实际为准",
ProportionOfContractedHotelStarLevelTransactions: "协议酒店星级交易额占比",
ProportionOfDomesticFullPriceTickets: "国内全价票比例",
ProportionOfHotelRoomNightsInAgreement: "协议酒店间夜占比",
ProportionOfNetTransactionPrice: "成交净价占比",
ProportionOfRCTimes: "RC次数占比",
PullToRefresh: "下拉刷新",
PullUpLoadMore: "上拉加载更多",
Punctuality: "准点率",
Punctuality_x: "准点率%a$",
PurchaseXItemAtMost_x: "限购%a$件",
Purpose: "差旅目的",
PutTheQRCodeIntoTheBox: "将二维码放入框内，即可自动扫描",
QrCode: "二维码",
QRCodeHasExpired: "二维码已失效",
QuantifierHotel: "间",
QuantifierNight: "晚",
QuantifierPeople: "人",
Quantity: "机票张数",
Quarterly: "季度",
QueryConditions: "查询条件",
QueryNotJurisdiction: "您没有查询权限",
QueryQuoteErrors: "查询报价失败",
QueryRange: "查询范围",
QueryResultOfTheCurrentCity: "当前城市查询结果",
QueryType: "查询类型",
QuickSelection: "快捷选择",
R: "退",
RankDescription: "差标描述",
RankInformation: "差标信息",
RankManagement: "差标管理",
RankName: "差标名称",
Rating: "星(钻)级",
RatingHighToLow: "星级高-低",
RatingLowToHigh: "星级低-高",
RatingMultipleChoice: "星级(可多选)",
RCContent: "RC内容",
RCManagement: "RC管理",
RCType: "RC类型",
Read: "已读",
Ready_x: "预计%a$",
Reapply: "重新申请",
ReasonForNotSelectingTheCheapestFlight: "未选择最低价航班原因",
ReasonForNotSelectingTheLowestPrice: "未选择最低价原因",
ReasonForTheRefundTicket: "退票类型",
ReasonForTicketIssueFailure: "出票失败原因",
ReasonForTravelRankingViolation: "违反差旅标准原因",
ReasonsForBusiness: "出差事由",
ReasonsForBusiness_x: "出差事由：%a$",
ReBook: "再次预订",
ReceiveMode: "接收方式",
RecentSearches: "历史",
Recharge: "充值",
RechargeAmount: "充值金额",
Recipient: "收货人",
Recommend: "大家都去",
Recommend_1: "热门推荐",
RecommendCabinPrice: "为您推荐相同航班的优选价格：",
Recommended: "智能排序",
RecommendedFlights: "推荐航班",
RecommendedTransferFlights: "中转航班推荐",
RecordsExchange: "兑换记录",
ReElection: "重选舱位",
RefreshFlight: "刷新航班",
RefreshPrices: "刷新价格",
RefreshSucceed: "刷新成功",
Refund: "退票",
Refund_1: "退 票",
RefundAmount: "退款金额",
RefundAmountPendingApproval: "退款金额待审核",
RefundCertificate: "退票证明",
RefundChangeAndBaggageInstructions: "退改签及行李说明",
RefundDetails: "退款明细",
RefundFailed: "退票失败",
RefundFee: "退票费",
Refunding: "退票中",
RefundInstructions: "退票规则",
RefundOrChangeExplain: "退改说明",
RefundOrderDate: "退票申请日期",
RefundOrderDetails: "退票详情",
RefundOrderNumber: "退票订单号",
RefundOrderNumber_x: "退票订单号%a$",
RefundOrderTime: "退票申请时间",
RefundPassenger: "退票乘机人",
RefundPolicy: "退票规定",
RefundRate: "退票率",
RefundReason: "退票原因",
RefundSucceed: "退票成功",
Regard: "关注",
RegardEmployees: "我关注的人",
RegardFailed: "关注失败",
RegardSuccess: "关注成功",
Register: "注册",
Register_1: "注册信息",
Register_2: "手机验证",
Register_3: "等待审核",
RegisterSuccess: "我们将尽快与您联系，请保持电话通畅，谢谢！",
Reject: "拒绝",
RelationAccount: "绑定账号",
RelationLogin: "绑定并登录",
RelationToHOMSOM: "请绑定登录账号",
ReleaseImmediateLoad: "松开立即加载",
ReleaseImmediateRefresh: "松开立即刷新",
ReleaseVoice: "松开结束",
Relevance: "关联",
Remaining_1_x: "剩余%a$件",
Remaining_x: "剩%a$张",
RemainingRoom_x: "仅剩%a$间",
RemainingUseLxd_x: "%a$个(1个旅行豆=1元人民币)",
Remarks: "备注",
ReminderYouCanImportFrequentTravelerInFrequentTravelerManagement: "温馨提示：常旅客还可以在常旅客管理中通过导入人员信息来进行添加。",
RemovalFailedPleaseTryAgainLater: "移除失败，请稍后再试",
RemoveFrequentTraveller: "移除该常旅客",
RemoveFromTheTiedList: "从已绑列表中删除",
RemoveSuccessful: "移除成功",
RemoveThe_x: "删除该%a$",
RemovetheCostCenter: "删除该成本中心",
RemoveTheDepartment: "删除该部门",
RemoveTheEmployee: "删除该员工",
RemoveTheNonEmployee: "删除该外部人员",
RemoveTheRC: "删除该RC",
RemoveThisFrequentContact: "删除该常用联系人",
RenovationYears: "装修时间",
ReportTotal: "总计",
ReQuery: "重新查询",
RequiredChoose: "必选",
RequiredChoose_x: "需选择%a$人",
RequiredChooseArrivalCityAddressDesc: "(必选)出差途经城市皆需添加",
RequiredChoosePleaseChoose: "(必选)请选择",
RequiredChooseUneditable: "(必选)不可编辑",
RequiredFill: "必填",
RequiredFillPleaseFill: "(必填)请填写",
RequiredFillPleaseFillOrChoose: "(必填选)请填写或选择",
RequiredFillUneditable: "(必填)不可编辑",
RequiredReadingForBook: "出行必读",
RequiredReadingForChange: "改期须知",
RequiredReadingForHotel: "订房必读",
RequiredReadingForRefund: "退票须知",
ReScan: "重新扫描",
Reschedule: "申请改期",
Rescheduled: "改期",
Rescheduling: "改期行程",
ReschedulingFee: "改期费",
ReschedulingReason: "改期原因",
Reselect: "重新选择",
Reselect_1: "重选去程",
ReselectFlight: "重新选择航班",
ReSelectPrice: "重选价格",
Resend: "重新发送",
Resend_x: "重新发送 %a$s",
ResendAfterXSeconds_x: "%a$s后重新发送",
ResendConfirmationMessage: "重新发送确认信息",
ResendPleaseWait: "重新发送请等待",
ResendPleaseWait_x: "重新发送请等待%a$",
Reset: "重置",
ResetFilter: "重置筛选",
ResetPassword: "重置密码",
ResetUsingBoundEmail: "使用已绑定的邮箱重置",
ResetUsingBoundPhoneNumber: "使用已绑定的手机号重置",
Rest: "休",
Result: "搜索结果",
RetrieveByEmail: "邮箱找回",
RetrieveByMobile: "手机号找回",
RetrievePassword: "找回密码",
Return: "返程",
Return_1: "返:",
ReturnAndChangeInstructions: "退改签说明",
ReturnColon: "返:",
ReturnDate: "返程日期",
ReturnTicket_x: "%a$(返程)",
ReVetting: "重发审批",
RevisedFlightInformation: "变更后的航班信息：",
ReviseTheDifference: "改期差价",
RideInfo: "乘车信息",
RMB: "元",
RMB_x: "%a$元",
RMBNight: "元/间夜",
RMBPrice_x_x: "¥%a$-%b$",
RMBPriceOver_x: "¥%a$以上",
RMBPriceUnder_x: "¥%a$以下",
RoleDescription: "角色描述",
RoleDetail: "角色详情",
RoleManagement: "角色管理",
RoleName: "角色名称",
Room: "客房",
Room_x: "%a$间",
RoomAdult_x_x: "%a$间 %b$成人",
RoomAdult_x_x_x: "%a$间 %b$成人 %c$儿童",
RoomAndNight_x_x: "%a$间 %b$晚",
Roomer: "入住人",
RoomerDetails: "入住人详情",
RoomerName: "入住人姓名",
RoomFees: "房费",
RoomNight: "间夜",
RoomNightPrice_x_x: "%a$间·%b$晚",
RoomNoXAtLeastChooseOneRoomer_x: "房间%a$至少需要选择一位入住人",
RoomsAndGuests: "房间及住客",
RoomType: "房型",
RoomTypeDetails: "房型详情",
RoomTypeRemarks: "房型备注",
RoundTrip: "往返",
Route: "航线",
RouteDepartDateCannotBeLessThanLastRoute_x_x: "第%a$程出发时间不能小于第%b$程出发时间",
RUB: "卢布",
Rules: "规则",
SafetyAlarmRequired: "安全报警必读",
SafetyManage: "安全管理",
SameCityTransfer: "同城换乘",
SameLevelApprover: "并级审批人",
SameStationTransfer: "同站换乘",
Save: "保存",
Save_1: "省",
Save_2: "保存修改",
Save_3: "节省",
SaveFailed: "保存失败",
SaveFailedFromCode: "二维码保存失败",
SaveOrderError: "保存订单错误，请尝试退至航班查询页面重新选择！",
SaveQRCode: "保存二维码",
SaveSucceed: "保存成功",
SaveSucceedToPhotoLibary: "已保存到相册",
SavingAnalysisofBookinginAdvance: "提前预订天数节省分析",
SavingAndMissedSaving: "节省分析",
Savings: "节省金额",
SavingsLosses: "节省与损失",
ScaleLevel_1: "1万-3万",
ScaleLevel_2: "3万-10万",
ScaleLevel_3: "10万-30万",
ScaleLevel_4: "30万-100万",
ScaleLevel_5: "100万以上",
ScanAndLogin: "扫码登录",
ScanCodeLogin: "扫描二维码登录",
ScanSuccessfully: "扫描成功",
ScanTheQRCode: "扫描二维码",
Schedule: "时刻表",
ScheduledItinerary: "已订行程",
SchemIndex_x: "方案%a$",
SchemIndexSelect_x: "方案%a$(已选中)",
ScoreByComments: "分",
Search: "查询",
Search_1: "搜索",
Search_2: "点击查询",
Search_3: "查 询",
SearchCanBeRescheduled: "查询可改期航班",
SearchFlight: "机票查询",
SearchForMoreCities: "搜索更多城市",
SearchHistory: "搜索历史",
SearchHotel: "酒店查询",
SearchThisArea: "搜索此区域",
Seat: "席位",
SeatInfo: "选座信息",
SeatNumber: "座位号",
SeatSelectionDepartingTrip: "选座(去程)",
SeatSelectionReturnTrip: "选座(返程)",
SeatSelectionSucceed: "选座成功",
SecondClass: "二等座",
SecondStep: "第二步",
SecurityAlarmDesc: "遇到危险，请及时110报警",
SecurityAssistant: "安全助手",
SecurityCenter: "安全中心",
SecurityCode: "安全码",
See: "查看低价",
SeeAll: "查看全部",
SeeAll_x: "查看全部%a$个价格",
SeeDetails: "查看详情",
Segment: "行程",
SegmentRecommend: "推荐",
Select: "已选",
Select_1_x: "已选：%a$",
Select_x: "选%a$",
SelectAll: "全选",
SelectApprover: "选择审批人",
SelectCabin: "选择舱位",
SelectCarPlatform: "选择用车平台",
SelectCarScene: "选择用车场景",
SelectCity: "选择城市",
SelectConfirmationOrder: "选择确认单",
SelectContact: "选择联系人",
SelectCustomItems: "选择自定义项",
SelectDate: "选择日期",
SelectDeparting: "选去程",
Selected: "选中",
Selected_1: "已选中",
Selected_x_x: "已选择%a$个%b$",
SelectedAddress: "选择地点",
SelectedAsGoing: "选为去程",
SelectedFlights: "已选择航班",
SelectedPassengers: "已选择乘机人",
SelectedTraveler_x: "已选：%a$人",
SelectEmployees: "选择员工",
SelectFlight: "选择航班",
SelectFlightChangeInfo: "选择改期航班信息",
SelectFlightChangeStep1: "01.选择改期的乘机人",
SelectFlightChangeStep2: "02.选择改期的行程",
SelectFlightChangeStep3: "03.选择改期的日期",
SelectFlightOnline: "选择值机行程",
SelectFlightRefund: "选择退票行程",
SelectFlightRefundInfo: "选择退票航班信息",
SelectFlightRefundStep1: "01.选择退票的乘机人",
SelectFlightRefundStep2: "02.选择退票的行程",
SelectFlightRescheduling: "选择改期行程",
SelectFromTheAlbum: "从相册中选择",
SelectInvoicingType: "选择发票类型",
Selection: "选座",
SelectionResult: "选座结果",
SelectLanguage: "选择语言",
SelectOneWay: "选单程",
SelectPassengers: "选择乘客",
SelectReturn: "选返程",
SelectTheCardExpirationMonth: "选择月份",
SelectTheCardExpirationYear: "选择年份",
SelectTheNumberOfRooms: "选择间数",
SelectThePassenger: "选择乘机人",
SelectTheReasonForTheRefundTicket: "选择退票类型",
SelectTheRescheduleType: "选择改期类型",
SelectTrain: "选择车次",
SelectYourCabin: "舱位选择",
SelectYourSeat: "选择",
SelectYourSeatDeparting: "去程选择",
SelectYourSeatReturn: "返程选座",
Self: "本人",
SelfPay: "自付",
Send: "发送",
SendATo12306: "发送至12306",
SendDistressMessage: "发送求救短信",
SendEmailOrPhone: "发送至邮箱/手机",
SendFailure: "发送失败",
SendingLXD: "送旅行豆",
SendMail: "发送邮件",
SendMailSuccessfullyPleaseCheckTheMailboxToResetThePassword: "发送邮件成功, 请查看邮箱重新设置密码",
SendMoneyToTheFollowingAccount: "汇款至以下账户",
SendSMS: "我们已经向",
SendSMS_1: "发送了一条验证短信，请输入您收到的验证码。",
SendSMS_2: "发送短信",
SendSMS_x: "我们已经向%a$发送了一封验证短信，请输入您收到的验证码。",
SendSucceed: "发送成功",
SendTo12306_x: "发送 %a$ 至 12306",
SendVerificationCode: "发送验证码",
SendVerificationCode12306: "请发送666至12306",
SendVerificationCodeTo12306ByMobilePhoneUpToTimesPerDay_x_x: "用手机%a$发送验证码%b$至12306，每天最多发送3次",
SerialNumber: "流水号",
Service: "客服",
Service_1: "转人工",
ServiceAgreement: "服务协议",
ServiceFee: "服务费",
ServiceFeePerPerson_x: "服务费 %a$/人",
ServiceLife: "使用有效期",
SetAsDefaultContact: "设为默认联系人",
SetAsStaff: "设为员工",
SetEmergencyContact: "设置紧急联系人",
SetNewPassword: "设置新密码",
SetSuccessfully: "设置成功",
Setting: "设置",
SettlementMethod: "结算方式",
SettlementPeriod: "结算账期",
SettleNow: "现结",
SGD: "新加坡元",
ShareDescPaymentOnBehalf_x: "我在恒顺商旅预订了%a$，需要你帮我付款哦~",
SharedFlight: "共享航班",
SharePDF: "分享PDF",
ShareTitlePaymentOnBehalf_x: "%a$希望你帮他付款",
ShareWithFriends: "分享给好友",
ShippingAddress: "收货地址",
ShowAvailableTicketsOnly: "只看有票",
ShowMoreSuppliers: "查看更多服务商",
ShowTheFrontOfYourFace: "展示脸部正面",
SigningPolicy: "签转规定",
SignOut: "退出当前账户",
SignOut_1: "退 出 当 前 账 户",
Skip: "跳过",
SlideUpToSeeMore: "上滑查看更多",
SlideYourFingerUp: "手指上滑，取消发送",
Smoke: "吸烟",
SMS: "短信核验",
SMSVerification: "短信验证",
Snatch: "抢",
SnatchDeadline_x: "发车前%a$",
SnatchDeadlineHour_x: "发车前%a$小时",
SnatchDeadlineMinute_x: "发车前%a$分钟",
SnatchTicket: "抢票",
SnaTchTicketDetails: "抢票详情",
SoldOut: "已售罄",
SorryCantFind: "对不起，找不到",
SorryCouldNotFindIt_x: "抱歉，没有找到“%a$”相关的内容",
SorryNoCabinAvailable: "很抱歉，暂无舱位",
SorryNoMatchingFlight: "抱歉，无符合要求的航班！",
SorryNoMatchingHotel: "抱歉，无符合要求的酒店！",
SorryNoMatchingRoom: "抱歉，无符合要求的房型！",
SorryNoMatchingTrain: "抱歉，无符合要求的车次！",
SorryThePageYouVisitedDoesNotExist: "非常抱歉，您访问的页面不存在。",
SorryThereAreNoSearchResultsFor_x: "抱歉，没有 %a$ 的搜索结果。",
Sort: "排序",
SpecialLineForTravelConsultants: "差旅顾问专线",
SpecialOffer: "特价",
SpecificRequirements: "特殊需求",
Spending: "支出",
Staff: "员工",
StaffAdd: "新增员工",
StaffNo: "员工编号",
StarComfort: "三星(钻)",
StarComfort_1: "三钻",
StarEconomic: "二星(钻)及以下",
StarEconomic_1: "二钻及以下",
StarLuxury: "五星(钻)",
StarLuxury_1: "五钻",
StarPremium: "四星(钻)",
StarPremium_1: "四钻",
StarRating: "星级",
Start: "开始",
StartDate: "开始日期",
StationDropOff: "送站",
StationDropOff_x: "%a$送站",
StationName: "车站名称",
StationPickup: "接站",
StationPickup_x: "%a$接站",
Stay: "停留",
Stay_1_x_x: "%a$停留%b$",
Stay_2_x_x: "中转%a$ 停留%b$",
Stay_3_x_x: "经停%a$ 停留%b$",
Stay_x: "停留%a$",
Stayed: "曾到过",
Stop: "经停",
Stop_1: "停",
Stop_1_x: "%a$停",
Stop_2: "经停",
Stop_2_x: "%a$个经停",
Stop_x: "经停%a$",
StopBy_x: "经 %a$",
StopInfo: "经停信息",
StopsFor_x: "停留%a$",
StowMoreSuppliers: "收起更多供应商",
SubmissionTime: "提交时间",
Submit: "提交",
Submit_2: "提 交",
SubmitAndPay: "提交并支付",
SubmitOrder: "提交订单",
SubmitOrderForChange: "提交改期订单",
SubmitSucceed: "提交成功",
Submitted: "已提交",
SubmittedRefund: "提交退票",
SubmitVetting: "提交审批",
Subsidiary: "积分明细",
SubwayStation: "地铁站",
SuccessfullySaved: "保存成功",
SuccessfulPasswordModification: "修改密码成功",
SuperEconomyClass: "超级经济舱",
Superior_x: "上级%a$",
SuperiorCostCenter: "上级成本中心",
SuperiorDepartment: "上级部门",
SupportingMixedPayment: "支持超标付",
Surname: "姓(拼音)",
SurnameEgLI: "如：HAN",
Surplus: "剩余",
SwimmingPool: "游泳池",
SwipeVerification: "滑动验证",
SwitchAccount: "切换账号",
SwitchAndAccess: "切换并访问",
SwitchLogin: "账号密码登录",
SwitchPerson: "切换会员",
SwitchPersonAccount: "切换会员账号",
SwitchUnit: "切换单位",
SwitchUnitAccount: "切换单位账号",
SyncDate: "同步日期",
SystemApproval: "系统审批",
SystemPermissionCamera: "您没有相机授权，请在设置中打开授权",
SystemPermissionDevice: "您没有授权读写设备，请在设置中打开授权",
SystemPermissionInstall: "安装此应用需要手动授权，请去设置中开启权限",
SystemPermissionLocation: "您没有授权定位，请在设置中打开授权",
SystemRecommendedPrice: "系统推荐价格",
SystemRole: "系统角色",
TakeAPicture: "拍照",
TakeOffTimeAnalysis: "起飞时间段分析",
TakesOff: "起飞",
TakesOff_x: "%a$起飞",
TakesOffArrival_x_x: "%a$到达, %b$起飞",
TalkAboutYourSuggestionOrProblem: "说说你的建议或遇到的问题",
Tax: "税",
Tax_1: "税",
Taxi: "打车",
TaxpayerIDNumber: "纳税人识别号",
TaxPrice_x: "税%a$",
TaxRegisterAddress: "税务登记地址",
TeamLink: "团队链接",
TeamQRcode: "团队二维码",
Telephone: "电话",
TemplateBound: "已绑定其他模板",
TemplateName: "模板名称",
TemplateNotes: "模板备注",
ThankYouForYourFeedback: "感谢您的反馈",
THB: "泰铢",
TheAgeOfTheChild_x: "第%a$位儿童年龄",
TheApplicationFormYouHaveSelectedNotSupportRoundTripSwitching: "您选择的申请单不支持往返切换",
TheCarMayChangePleaseCheckAgain: "车辆信息可能有变，请重新查询",
TheCarryingRegulationsFor: "携带锂电池和充电宝的乘机规定、",
TheCityHasBeenSelected: "已选择该城市",
TheCodeValueIsEmpty: "code值为空",
TheCurDatCanExcTheNexTriDatPleGoBacToTheHomPagToModify: "当前日期不能超过下一行程日期，请返回首页修改",
TheCurrentDeviceIsLockedOrNotBiometricEnabled: "当前设备被锁定或没有开通生物识别功能",
TheCurrentDeviceSupportsFaceID: "当前设备支持面容登录，是否开通？",
TheCurrentDeviceSupportsTouchID: "当前设备支持指纹登录，是否开通？",
TheCurrentLogin: "当前登录为",
TheCurrentNetworkIsNotAvailable: "当前网络不可用",
TheCurrentNetworkIsNotAvailable_1: "当前网络不可用，请检查连接状态",
TheCurrentOrderHasBeenSplitIntoMultipleOrdersPleaseGoToTheOrderListToView: "当前订单被拆分多张订单，请前往订单列表查看",
TheCurrentOrderIsAPersonalPaymentOrder: "当前订单为个人支付订单, 审批通过后请尽快支付进行出票",
TheCurrentOrderRequiresPersonalPayment1: "当前订单需个人支付, 待支付完成后, 才能进行出票.",
TheCurrentOrderRequiresPersonalPayment2: "当前订单需个人支付, 待支付完成后, 才能进行确认.",
TheCurrentPointsAreNotEnoughToExchangeThisItem: "当前积分不足以兑换此商品",
TheCurrentServiceNeedsToUseLocation: "当前服务需使用定位功能，请前往设置开启",
TheCurrentTimeIsNotAvailableForAppointments: "当前时间不在可预约的范围内",
TheDateOfDepartureCannotBeEarlierThanThePreviousProcess_x: "%a$出发日期不能超过上一个行程",
TheDriverIsGoingToTheDestination: "司机正在前往目的地",
TheDriverWillContactThePassenger: "司机将与该乘车人联系",
TheEmailNotificationHasBeenTurnedOn: "已开启邮件通知功能, 邮箱地址不能为空!",
TheFlightCombinationYouSelectedCannotBeReserved: "您选择的航班组合不可预订，请分开下单",
TheFlightHasInsufficientTickets: "该航班余票不足, 请选择其他航班",
TheFollowingTimesAreLocalTime: "以下时间均为当地时间",
TheIdentityOfPassengerXHasNotBeenVerified_x: "乘车人(%a$)身份信息待核验",
TheItineraryYouSelectedNotMatchTheApplicationForm: "您选择的行程与申请单行程不匹配",
TheLengthOfTheViolationOfTheTravelRankCannotExceedXWords: "违反差旅标准原因长度不可超过50个字",
TheLowestLogicalFareIsSuggestedAccordingToTheTravelPolicyOfYourCompany: "根据贵司规定，推荐您预订最低价航班",
TheLowestPriceForDomesticAirTicketIsNotSelected: "国内机票未选择最低价，需要选择的原因",
Theme: "特色",
TheNetworkSignalIsPoor: "网络不给力, 请稍后再试试吧~",
TheNewPassword: "新密码",
TheNewVersionIsOnlinePleaseUpdateToTheLatestVersion: "新版本已上线，请更新至最新版本。",
TheNextPageIsNotUnderOuControl_x: "下个页面不受我们控制哦，使用时请注意安全%a$",
TheNumberOfTheTicket: "取票号",
TheOrderConfirmedAndProofOfSuccessfulBookingFollowingMethods: "订单已确认，可通过以下方式获得预订成功证明。",
TheOrderIncludesAirfare: "点击下一步，表示已阅并同意关于",
TheOrderIncludesRescheduledPassengers: "该订单中有乘客已改期",
TheOrderIsForIndividualPaymentOrders: "该订单为个人支付订单, 改期业务请联系您的差旅顾问或致电: 4006-123-123",
TheOrderIssuedAndProofOfSuccessfulBookingFollowingMethods: "订单已出票，可通过以下方式获取机票确认单。",
ThePeriodOfValidity: "有效期",
ThePeriodOfValidityTips: "例：2025年6月，请输入0625",
ThePhoneNumberIsTheSameAsTheCurrentlyBoundMobilePhoneNumber: "该手机号与当前绑定的手机号相同",
TheRailwayBureauStipulatesThatTicketsMustBeSoldByRealName: "铁路局规定购票必须实名制",
ThereAreOtherFlightsAtTheSameTime: "同时段已有其他航班, 是否继续?",
TheReasonForExceedingTheStandardCannotExceedXWords: "超标原因长度不可超过50个字",
TheRefundFeeIsBeingApprovalByTheFlightCompany: "退票费航司审核中",
TheRefundFeeIsSubjectToTheReviewOfTheAirlineCompany: "退票费具体以航司审核为准",
ThereMeals: "有餐食",
ThereMustBeNoContactWithTheSamePhoneNumber: "不得存在联系手机号相同的联系人",
ThereNoAvailableRoomTypesForBooking: "暂无可预订房型",
ThereNoAvailableRoomTypesForBookingForChangeFilter: "没有找到符合条件的房型，您可以更改筛选项试试~",
TheRescheduleType: "改期类型",
TheReschedulingFeeIsBeingApprovalByTheFlightCompany: "改期费航司审核中",
TheReschedulingFeeIsSubjectToTheReviewOfTheAirline: "改期费具体以航司审核为准",
TheRoomerIDNumberCannotBeRepeated: "入住人证件号码不能重复",
TheRoomIsReservedAllNight: "房间整晚保留, 14:00前到店可能需要等房",
TheSpecificPriceIsSubjectToTheReview: "*具体价格以12306审核为准",
TheSpecificRefundAndChangeRulesShallBeSubjectToTheDateOfTheTicketIssue: "具体退改签规则以航司审核为准！",
TheSubmissionFailedPleaseTryAgainLater: "提交失败, 请稍候再试!",
TheSysteIsBusyPleaseReOperate: "系统繁忙, 请重新操作!",
TheSystemIsBusyPleaseTryAgainLater: "系统繁忙，请稍后再试！",
TheTakeoffAndLandingLocalTime: "航班起降时间均为当地时间",
TheTicketYouChooseIsVeryCloseToTheDepartureTime: "您选择的列车距开车时间很近了，请确保有足够的时间办理安全检查、实名制验证及检票等手续，因临近出发，会有出票失败风险，请您留意确认信息，以免耽误您的行程。",
TheTicketYouChooseIsVeryCloseToTheDepartureTimeChange: "您选择的列车距开车时间很近了，请确保有足够的时间办理安全检查、实名制验证及检票等手续，因临近出发，会有改签失败风险，请您留意确认信息，以免耽误您的行程。",
TheValidityPeriodHasExpired: "有效期已过期！",
TheValidityPeriodHasExpiredByReplace: "您的证件已经过期，请更换其他证件或延期后重新预订",
TheVerificationCodeHasBeenSentTo_1_x: "验证码已发送至 %a$",
TheVerificationCodeHasBeenSentTo_x: "已发送验证码至%a$",
ThirdPartyBinding: "第三方账号关联",
ThirdPartyRelation: "账号关联",
ThirdStep: "第三步",
ThisInformationIsForReferenceOnlyIfThereIsAnyChangePleaseReferToTheStationAnnouncement: "*该信息仅作为参考，如有变化，请以车站公告为准",
ThisOrderUseLxd_x: "本单您可使用%a$个旅行豆",
ThJourneyIsXAndIsExpectedToTakeXMinutes_x_x: "全程%a$公里，预计行驶%b$分钟",
TicketAmount: "出票金额",
TicketBarrier: "检票口",
TicketBarrierWaitingPlace: "检票口/候车地点",
TicketBookingAuthority: "国内机票差标",
TicketChangeRefund: "退改签情况",
TicketFare: "票价",
TicketingMail: "出票邮件",
TicketingSMS: "出票短信",
TicketingSucceed: "出票成功",
TicketNumber: "票号",
TicketPricesHaveChanged: "机票价格发生变更",
TicketRestrictions: "退改签",
TicketRestrictions_x: "退改%a$起",
TicketRestrictions_x_x: "退改%a$%b$起",
TicketRestrictionsArrow: "退改签 >",
TicketRestrictionsCancellationBaggage: "退改签 行李",
TicketRestrictionsCancellationBaggageArrow: "退改签 行李 >",
TicketRestrictionsRule: "退改签规则",
Tickets: "张",
Tickets_1: "/张",
Tickets_x: "%a$张",
TigerRobot: "小虎机器人",
Time: "兑换时间",
Time_1: "时间",
TimeArrival: "到时",
TimeConsuming: "耗时",
TimeConsumingLeast: "耗时从少到多",
TimeConsumingMost: "耗时从多到少",
TimeDeparture: "发时",
TimeHighToLow: "时间 晚-早",
TimeLowToHigh: "时间 早-晚",
TimeRange: "时间范围",
Tips: "提示",
Tips_1: "[提示]",
Tips_2: "温馨提示",
TipsColon: "温馨提示：",
TMS: "恒顺酒店TMS",
TMSOnline: "恒顺酒店TMS线上",
To: "至",
ToApply: "去申请",
ToBeConfirmed: "待确认",
ToBetterYourBusinessTripExperience: "为了让您的出差享受更优质的酒店",
ToChoose: "去选择",
Today: "今天",
ToEnsureThatThePassengerInformationIsCorrect: "为保证乘车人信息正确, 请按提示进行核验",
ToEnsureTheSecurityOfTheAccount: "为保障账户安全，请确认是本人操作",
ToGuarantee: "去担保",
ToGuaranteeAmount: "担保金额",
Tomorrow: "明天",
Tomorrow_1: "后一天",
Tomorrow_x: "次日%a$点",
Top5Cities: "前五城市",
Top5CostCenterExpense: "前五成本中心消费情况",
Top5DeptsExpenses: "前五部门消费分析",
Top5DestinationsbyExpense: "前五目的地消费情况",
TopFiveModels: "前五车型消费分析",
ToSetUp: "去设置",
Total: "合计",
Total_1: "往返总价:",
Total_2: "合计：",
Total_3: "往返总价",
TotalAmount: "总金额",
TotalAmount_1: "总消费金额",
TotalAmount_x: "总金额%a$",
TotalAmountOfCarUsed: "用车总金额",
TotalAmountOfTrainTicket: "火车票总金额",
TotalAmountPendingApproval: "总金额待审核",
TotalBudget: "总预算",
TotalBudgetAmount: "预算总金额",
TotalBudgetRMB: "总预算(元)",
TotalCabinInfo_x: "共%a$个航班信息",
TotalDuration: "总时长",
TotalDuration_x: "总时长%a$",
TotalMileage: "总里程",
TotalMissedSaving: "总体损失",
TotalNights: "%a$晚",
TotalNights_1: "共%a$晚",
TotalNumberOfPeople: "总人数",
TotalOfDay_x: "%a$天",
TotalOrderAmount: "订单总金额",
TotalPiece_x: "共%a$件",
TotalPrice: "总价",
TotalPrice_x: "总价%a$",
TotalPriceIncludingTax: "包含税/费",
TotalPriceNoTax: "不含税价",
TotalPriceNoTax_1: "往返总价(不含税)",
TotalPriceNoTax_2: "多程总价(不含税)",
TotalPriceTax: "含税价",
TotalPriceTax_1: "往返总价(含税)",
TotalPriceTax_2: "多程总价(含税)",
TotalSaving: "总体节省",
TotalTrip_x: "(共%a$程)",
ToTravel: "待出行",
TouchIDIsNotEnabled: "当前设备没有开通指纹ID",
TouchIDIsOnlyValidForTheMachine: "指纹ID仅针对本机有效",
TouchIDLogin: "指纹登录",
TourismConference: "旅游",
TrackingNo: "快递单号",
TrackingNumber: "快递单号",
Traffic: "周边交通",
Train: "火车",
TrainAccount: "12306账号",
TrainAccountLogin12306: "登录12036",
TrainAccountName: "账户名",
TrainAccountPwd: "账户密码",
TrainAccountPwd12306: "12306账号密码",
TrainAccountSubmitCheck: "提交核验",
TrainApplication: "火车票申请",
TrainAvailable: "有",
TrainBookPermission: "火车票预订权限",
TrainChanged: "车次信息可能有变，请重新查询",
TrainNumber: "车次",
TrainOrders: "火车票订单",
TrainPassenger: "乘客",
TrainRank: "火车票差标",
TrainSnatchCode: "备选车次",
TrainSnatchDate: "备选日期",
TrainSnatchDeadline: "截止时间",
TrainSnatchSeats: "备选坐席",
TrainStation: "火车站",
TrainTicket: "火车票",
TrainTicketConfirmation: "火车票订单确认",
TrainTicketFare: "火车票票价",
TrainTicketsBooking: "预订火车票",
TrainTicketSmsVerification_x_x_x: "请您通知乘车人(%a$)使用手机号码%b$在30分钟内发送短信%c$至12306进行核验。",
TrainTicketsYouSearchedFor: "你搜索过的火车票",
TrainType: "车次类型",
TrainUnavailable: "无",
Transfer: "中转",
Transfer_1: "转",
Transfer_1_x: "%a$转",
Transfer_2: "中转",
Transfer_2_x: "%a$程中转",
Transfer_Stop_x_x: "%a$程中转%a$个经停",
Transfer_x: "中转%a$",
TransportationAccommodation: "交通住宿",
TransportTime: "用车时间",
TravelApplication: "出差申请单",
TravelApplicationCode: "出差申请单号",
TravelBooking: "差旅预订",
TravelCard: "常旅卡",
TravelControl: "差旅管控",
Traveler: "出行人",
Traveler_1: "出差人员",
Traveler_x: "出差人员：%a$",
TravelerDetails: "出行人详情",
TravelerType: "旅客类型",
TravelPolicy: "差旅标准",
TravelPositioning: "差旅定位",
TravelPurpose: "差旅目的",
TravelReport: "差旅报表",
TravelSecurity: "出行保障",
TravelStandard: "差标",
TravelStandard_1: "我的差标",
TravelTime: "出行时间",
TravelType: "差旅类型",
Trip: "行程",
TripType: "行程类型",
TryAgain: "再试一次",
TryAgain_1: "再 试 一 次",
TWD: "台币",
TwinBed: "双床",
Type: "类型",
Type_1: "原因类型",
TypeOfQuestionRequired: "问题类型(必填)",
TypeOfTrip: "旅行类型",
UnableToBookTheCurrentAgreementPriceProduct: "很抱歉，您的订单中的乘机人不在航司白名单，因此无法预订当前协议价产品。",
UnableToLocateCurrentCity: "无法定位当前城市",
Unbind: "去绑定",
UnbindSuccessfully: "解绑成功",
Unbound: "未绑定",
UnboundApprover: "未绑定指定审批人",
UnboundCustomization: "未绑定自定义项",
UnboundEmployees: "未绑定员工",
UnboundTemplate: "未绑定模板",
UnderOneYearOld: "1岁以下",
UnderTheCurrentCostCenterNoCostCenter: "当前成本中心下暂无子成本中心",
Uneditable: "不可编辑",
Unfilled: "未填写",
Unfinished: "未结束",
Unfold: "展开",
UnHandleAuthorization_x: "您有待处理的申请单(%a$)",
UnhookYourFingersAndCancelSend: "松开手指，取消发送",
Unit: "单位",
Unit_1: "个",
Unit_1_x: "%a$份",
Unit_x: " x %a$份",
Unit_x_x: "%a$ x %b$份",
UnitAbbreviation: "单位简称",
UnitAccount: "单位账号",
UnitEntry: "单位入口",
UnitLogo: "单位logo",
UnitManagement: "单位管理",
UnitName: "单位名称",
UnitNumber: "单位编号",
UnitNumber_x: "单位编号：%a$",
UnitNumberOrUnitName: "单位编号或单位简称",
Unknown: "未知",
Unselected: "未选择",
UnStaffAdd: "新增外部人员",
Unsubmitted: "未提交",
Untie: "解绑",
UnusedTicketTitle_x: "乘机人：%a$有以下行程的未使用机票，您可以通过改期来预订当前行程，如果需要预订可以点击【去改期】按钮进行操作。",
Update: "马上升级",
Update_1: "去更新",
UpdateSearchWhenPullingMap: "拉动地图时更新搜索",
Upgrading: "升级中",
UploadCertificate: "上传证明",
UploadedCertificate: "已上传证明",
UploadFile: "上传文件",
Upscale: "高档",
UrbanCivision: "城市划分",
USD: "美元",
UseCar: "用车",
UsedForCheckingInAtTheHotelFrontDeskOrForProcessingDestinationVisas: "用于在酒店前台办理入住，或办理目的地签证时使用",
UseLxd: "使用",
UseLxd_x: "最高可使用%a$旅行豆",
UserCanceled: "用户取消",
UserName: "用户名",
UserNameEmailMobile: "12306用户名/邮箱/手机号",
UseRules: "使用细则",
UseTheCar_x: "%a$ 用车",
UseTheCarImmediately: "立即用车",
UsingTheLastestVersion: "您使用的已是最新版本",
ValidationCertificateInformationNotPassed_x: "%a$证件信息核验未通过",
ValidPeriod: "有效期至",
VehicleDisinfection: "车辆消毒",
VehicleTrack: "用车轨迹",
VerificationCode: "验证码",
VerificationCodeAgain: "重新获取验证码",
VerificationCodeCheck: "输入短信验证码，完成核验",
VerificationCodeCheckFail: "验证码校验失败",
VerificationCodeCoped: "验证码已复制",
VerificationCodeCopiedSuccessful: "验证码复制成功",
VerificationCodeFailedToSend: "验证码发送失败",
VerificationCodeGet: "获取验证码",
VerificationCodeHasBeenSent: "验证码已发送",
VerificationSucceeded: "校验成功，通过！",
VerifyUsingMail: "使用邮箱验证",
VerifyUsingPhoneNumber: "使用手机号验证",
VersionNumber: "版本号",
VersionUpdate: "版本更新",
ViewAllStaff: "可查看全部员工",
ViewBookPermissions: "查看/预订权限",
ViewComments: "查看评论",
ViewConfirmNumber: "查看确认单",
ViewDesignated_x: "可查看指定%a$",
ViewDesignatedDepart: "可查看指定部门",
ViewDesignatedStaff: "可查看指定员工",
ViewDirectTicket: "查看直达车票",
ViewElectronicPolicy: "查看保单",
ViewItineraryTrack: "查看行程轨迹",
ViewMoreTransferSchemes: "查看中转方案",
ViewOnlySelf: "仅限查看本人",
ViewOrder: "查看",
ViewOtherPrices_x: "查看其它%a$个价格",
ViewPermissions: "查看权限",
ViewRoomType: "查看房型",
ViewTheBigMap: "查看大图",
ViewTheLowestPrice: "查看最低价航班",
ViewTravelApplicationForm: "查看出差申请单",
ViolationOfBusTravelRank: "违反汽车票差旅标准，需要选择的原因",
ViolationOfCarTravelRank: "违反用车差旅标准，需要选择的原因。",
ViolationOfHomsomTravelStandardsNotSelectable: "违反贵司的差旅标准,不可选择",
ViolationOfHomsomTravelStandardsSelectedReason: "违反贵司的差旅标准，需选择原因：",
ViolationOfHotelTravelRank: "违反酒店差旅标准，需要选择的原因",
ViolationOfMealsTravelRank: "违反用餐差旅标准，需要选择的原因",
ViolationOfRank: "违反差标",
ViolationOfRankDetail: "违反差标详情",
ViolationOfRankReason_x: "违反差标原因：%a$",
ViolationOfTrainTravelRank: "违反火车票差旅标准，需要选择的原因",
ViolationOfTravelRank: "违反差旅标准",
VoidApplicationForm: "作废申请单",
VoidedFailed: "作废失败",
VoidedSuccessfully: "作废成功",
VoluntaryRefund: "自愿退票",
VoluntaryRefundDesc: "自愿退票(按航空公司退改规则收费)",
VoluntaryRefundExplain: "自愿退票说明",
VoluntaryReschedule: "自愿改期",
VoluntaryRescheduleDesc: "自愿改期(按航空公司退改规则收费)",
VoluntaryRescheduleExplain: "自愿改期说明",
Voucher: "抵用券",
WaitingForConfirmation: "等待确认",
WarmTipsWhitelistedEmployees: "温馨提示: 白名单内员工，无需审批。",
WarmTipTheModificationIsNotSupportedForTheTimeBeing: "温馨提示：暂不支持修改，如需修改请联系您的专属顾问",
Wechat: "微信",
WeChat_Limit_1: "因小程序限制，请在恒顺商旅APP或网站中进行该操作",
WechatByAccount: "企业微信账号",
WechatByUser: "企业微信员工绑定",
WechatIsNotInstalled: "微信未安装",
WechatNotSupportDDPleaseUseAppOrH5: "微信小程序暂不支持滴滴用车，请使用app或h5预定",
WechatNotSupportOnlineCheckInPleaseOpenOnPcOrApp: "微信小程序暂不支持在线值机，请使用pc、app或h5打开",
WechatPay: "微信支付",
WeekFri: "五",
WeekFri_1: "周五",
WeekMon: "一",
WeekMon_1: "周一",
WeekSat: "六",
WeekSat_1: "周六",
WeekSun: "日",
WeekSun_1: "周日",
WeekThur: "四",
WeekThur_1: "周四",
WeekTues: "二",
WeekTues_1: "周二",
WeekWed: "三",
WeekWed_1: "周三",
Welcome: "Hi，欢迎您！",
Welcome_x: "%a$，欢迎您！",
WelcomeToHOMSOM: "欢迎来到恒顺商旅",
WelcomeToHumanCustomerService: "欢迎进入人工客服",
WelcomeToLogin: "您好，欢迎登录。",
WelcomeToLoginHomsom: "欢迎登录恒顺商旅",
WhetherToAcceptTheSeatlessTicket: "当所选坐席余票不足时,是否接受无座票",
WhetherToConfirmTheDeletionOfThisFavoriteContact: "确认删除该常用联系人",
WhetherToConfirmThePasswordChange: "是否确认修改密码?",
WhetherToConfirmTheRefund: "是否确认退票?",
WhetherToSendARefundEmail: "是否发送退票邮件",
WhetherToSendARefundEmail_1: "发送退票邮件",
WhetherToSendARefundSuccessfulMail: "是否发送退票成功邮件",
WhetherToSendARefundSuccessfulMail_1: "发送退票成功邮件",
WhetherToSendARefundSuccessfulSMS: "是否发送退票成功短信",
WhetherToSendARefundSuccessfulSMS_1: "发送退票成功短信",
WholeRoute_x: "全程%a$",
Window: "窗",
WindowType: "窗户",
Withdrawal: "提现",
Work: "班",
WriteReasonCode: "填写ReasonCode",
XFormatIsIncorrect_x: "%a$格式不正确",
XHoursBeforeAndAfterTheLowestPrice_x: "前后%a$小时最低价",
XIDIsWrong_x: "(%a$)证件信息校验不通过",
XIsUnderXyearsOld_x: "%a$未满12周岁",
XOfFlight_x: "飞行%a$",
XPeoplePerRoom_x: "每间可住%a$人",
XTrips_x: "共%a$车次",
Year: "年",
Year_x: "%a$年",
YearOnYear: "同比",
Yesterday: "前一天",
You: "您",
YouAlsoHaveUnselectedPassengers: "您还有未选座的乘客，请选座完成后再提交订单！",
YouCanDoTheFollowing: "您可以进行以下操作",
YouCannotSelectContactsWithTheSamePhoneNumberAtTheSameTime: "不得同时选择手机号相同的联系人",
YouCanOnlyGetSMSVerificationCodesFor3MobilePhoneChecksPerDay: "每天仅能获取3次手机核验的短信验证码",
YouCanOpenItInMySettingSecurityManager: "您可在我的-设置-安全管理中开通",
YouCanUseFaceIDVerification: "开启后，可使用面容ID验证，快速完成登录",
YouCanUseTouchIDVerification: "开启后，可使用指纹ID验证，快速完成登录",
YouHaveACarOrderInProgress: "您有一个用车订单正在进行",
YouHaveToDoFaceRecognitionAlone: "必须一个人进行人脸识别",
YouHaveUnselectedPassengersPleaseSubmitAfterSeatSelection: "您有未选座的乘客，请选座完成后再提交",
YourCompanyAllowsYouToPayTheExtraAmountForAnOutPolicyHotelByCreditCardWeChatOrAlipay: "公司允许超额部分个人补贴，可用微信、支付宝支付超标部分。",
YourCurrentLoginAccountNeedsMobilePhoneNumberVerification: "您当前登录的账号需进行手机号核验",
YourDateOfBirthGenderNationalityAndIDCardDoNotMatch: "您的出生日期和性别国籍与身份证不符，已为您更正",
YourExclusiveTravelConsultant: "您的专属差旅顾问",
YourHotelGroupMembershipCard: "您的酒店集团会员卡",
YourOrderHasNotBeenCompletedOut: "您的订单尚未完成,是否确定要离开当前页面?",
YourOrderHasNotBeenFilledOut: "您的订单尚未填写完成,是否确定要离开当前页面?",
YourPendingApplicationForm_x: "您有待处理的申请单(%a$)",
YourPhoneNumberHasNotBeenRegisteredYet: "您的手机号还没有注册",
YourStayIsTooLongTheFlightAndPriceInformationMayChangeAndTheLatestPrice: "您的停留时间过长，航班及价格信息可能发生变动，正在为您查询最新价格",
YourTravelCard: "您的常旅卡",
YouWill: "您将从",
YuanStart_x: "%a$元起",
}